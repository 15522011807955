export const formatNumberWithSpaces = (number) => {
  if (typeof number !== "number") {
    number = parseFloat(number);
  }
  if (isNaN(number)) {
    throw new Error("Input is not a valid number");
  }
  // Check if the number is an integer
  if (Number.isInteger(number)) {
    number = number.toFixed(1);
  }
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
  return parts.join(".");
};

export const formatNumberTwodecimals = (number) => {
  if (Number.isInteger(number)) {
    number = number.toFixed(1);
  }
};

export const getRandomString = () => {
  const randomItems = new Uint32Array(28);
  window.crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map(
    (dec) => `0${dec.toString(16).substr(-2)}`
  );
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "");
};

export const hashToBase64url = (arrayBuffer) => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce(
    (acc, i) => `${acc}${String.fromCharCode(i)}`,
    ""
  );
  const decodedHash = btoa(stringifiedArrayHash);
  const base64URL = decodedHash
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64URL;
};

export const encryptStringWithSHA256 = async (str) => {
  const PROTOCOL = "SHA-256";
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return crypto.subtle.digest(PROTOCOL, encodedData);
};
