import React from "react";
import { useState, useEffect, useContext } from "react";
import { CookiesProvider } from "react-cookie";
import Layout from "../../components/layout";
import { Divider, Box, Container, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import workBench from "../../assets/img/workBench.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReachDevelopment from "../../components/Applications/Reach/development";
import WrapDevelopment from "../../components/Applications/Wrap/Development/development";
import SwitchDevelopment from "../../components/Applications/Switch/development";
import ReachProduction from "../../components/Applications/Reach/production";
import WrapProduction from "../../components/Applications/Wrap/Production/production";
import LoopIcon from "@mui/icons-material/Loop";
import SwitchProduction from "../../components/Applications/Switch/production";
import BasicDetails from "../../components/Applications/viewApplication";
import CloneConfigDev from "../../components/Applications/cloneConfigDev";
import DoneIcon from "@mui/icons-material/Done";
import CloneConfigPopUp from "../../components/Applications/cloneConfigProd";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Services from "../../api/services";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import VersionPanel from "../../components/Applications/versionPanel";
import ChangeLog from "../../components/Applications/changeLog";
import Loader from "../../components/loader";
import {
  switchStatusContext,
  statusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import BreadCrumb from "../../components/breadCrumb";
import useToast from "../../hooks/useToast";

import BuildHistory from "../../components/Applications/buildHistory";
import BuildApk from "../../components/Applications/buildApk";
import GetAppStatus from "../../components/Applications/getAppStatus";
import BuildApkPopUp from "../../components/Applications/buildApkPopUp";
import GoogleAppIdPopUp from "../../components/Applications/googleAppIdPopUp";
import { WarningMessages } from "../../config/messages";
import ConfirmationPopup from "../../components/confirmationPopup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  color: "#01537A",
  fontWeight: 400,
  fontSize: "18px",
  textTransform: "capitalize",
  letterSpacing: "0.5px",
  background: "white",
  borderRadius: "5px 5px 0px 0px",
  border: "1px solid ",
  borderColor: "#01537A",

  "&.Mui-selected": {
    background: "#01537A",
    color: "white",
  },
  "&.MuiTab-root": {
    minHeight: "0px",
  },
};

export default function ApplicationDetails() {
  const params = useParams();
  const location = useLocation();
  const selectedAppId = location.state?.clickedAppId;
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  let navigate = useNavigate();
  const applicationPopUp = localStorage.getItem("applicationPopUp");
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [disableGetAppStatus, setDisableGetAppStatus] = useState(false);
  const [productType, setProductType] = useState();
  const [applicationData, setApplicationData] = useState();
  const [appId, setAppId] = useState();
  const [value, setValue] = React.useState(0);
  const [configPopUp, setConfigPopUp] = useState(false);
  const [appStatus, setAppStatus] = useState(false);
  const [buildHistory, setBuildHistory] = useState(false);
  const [buildApk, setBuildApk] = useState(false);
  const [buildApkPopUp, setBuildApkPopUp] = useState(false);
  const [googleAppIdPopUp, setGoogleAppIdPopUp] = useState(false);
  const [cloneConfigPopUp, setCloneConfigPopUp] = useState(false);
  const [openWorkBench, setOpenWorkBench] = React.useState(false);
  const [loadData, setLoadData] = useState(false);
  const [disablePublishApp, setDisablePublishApp] = useState(false);
  const [loadPublishApp, setLoadPublishApp] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // eslint-disable-next-line
  const [refreshApi, setRefreshApi] = useState(false);
  const [deploymentIdExist, setDeploymentIdExist] = useState();
  const [load, setLoad] = useState(false);
  const [buildApKLoad, setBuildApkLoad] = useState(false);
  const [buildApkDisable, setBuildApkDisable] = useState(false);
  const [type, setType] = useState("DEV");
  const { showSuccess, showError, showWarning } = useToast();
  const errorMsg = `You cannot create an app that belongs to a Customer with status of “New”. Please contact administration `;
  const [userData, setUserData] = useState({
    applicationId: "",
    entryPoint: "",
    versionCode: "",
    versionName: "",
    packageSuffix: "",
    buildTypes: "",
    comments: "",
    distributionEmail: "",
  });

  const [userError, setUserError] = useState({
    applicationId: false,
    applicationIdMsg: "",
    entryPoint: false,
    entryPointMsg: "",
    versionCode: false,
    versionCodeMsg: "",
    versionName: false,
    versionNameMsg: "",
    comments: false,
    commentsMsg: "",
    distributionEmail: false,
    distributionEmailMsg: "",
    packageSuffix: false,
    packageSuffixMsg: "",
    buildTypes: false,
    buildTypesMsg: "",
  });

  let versionCodeRegex = /^[0-9\b]+$/;
  // eslint-disable-next-line
  let versionNameRegex = /^[^*\/\\?":'<>| ]+[^\/\\:*?"'<>| ]*$/i;
  const [customerData, setCustomerData] = useState();

  const [resData, setResData] = useState();
  const getApplicationDetails = () => {
    setLoading(true);
    const payload = {
      companyId: localStorage.getItem("companyId"),
      applicationId: selectedAppId,
    };
    Services.getApplicationDetails(payload)
      .then((res) => {
        if (res?.status === 200) {
          setResData(res?.data);
          if (res?.data?.status === "failed") {
            navigate("/");
            showWarning(res?.data?.message);
          } else {
            setAppId(
              type === "DEV" ? res?.data?.devAppId : res?.data?.prodAppId
            );
            setApplicationData(res);
            const customerId = res?.data?.publisherId;
            Services.LicenseeAccountSettings({ companyId: customerId })
              .then((res) => {
                setLoading(false);
                setCustomerData(res?.data);
              })
              .catch((err) => {});
            setProductType(res.data.productType);
          }

          if (res.data?.status === "Deleted") {
            localStorage.setItem("applicationStatus", "deleted");
          } else {
            localStorage.setItem("applicationStatus", "active");
          }
        }
      })
      .catch((err) => {});
  };

  const [deploymentId, setDeploymentId] = useState();
  const getDeploymentId = (id) => {
    setDeploymentId(id);
  };

  const handleWorkBenchClose = () => {
    setOpenWorkBench(false);
  };
  const handleCloneConfigPopUp = () => {
    setCloneConfigPopUp(false);
  };
  const handleClickDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleclickClose = () => {
    setOpenDelete(false);
  };
  const getCloneConfig = (e) => {
    if (
      customerData?.parentCompany?.[2]?.status === "New" ||
      customerData?.parentCompany?.[3]?.status === "New"
    ) {
      setOpenWarning(true);
    } else {
      setCloneConfigPopUp(true);
    }
  };

  const handleConfigPopUp = () => {
    setConfigPopUp(false);
  };

  const [getAppStatusData, setGetAppStatus] = useState();
  const [timeInWords, setTimeInWords] = useState();
  const handleAppStatusOpen = (type) => {
    setStatusLoading(true);
    setDisableGetAppStatus(true);
    if (appId) {
      Services.getAppStatus({ appId: appId })
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.publishedDate) {
              const t = formatDateDifferenceAndGivenDate(
                res?.data?.publishedDate
              );
              setTimeInWords(t);
            }
            if (res?.data?.status === "failed") {
              setAppStatus(true);
            } else {
              setAppStatus(true);
              setGetAppStatus(res?.data);
            }

            setStatusLoading(false);
            setDisableGetAppStatus(false);
          }
        })
        .catch((err) => {});
    } else {
      setGetAppStatus();
      setStatusLoading(false);
      setDisableGetAppStatus(false);
    }
  };

  function formatDateDifferenceAndGivenDate(givenDate) {
    const currentDate = new Date();
    const [datePart, timePart] = givenDate.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);
    const givenDateTime = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );
    const timeDifferenceInSeconds = Math.floor(
      (currentDate - givenDateTime) / 1000
    );
    const days = Math.floor(timeDifferenceInSeconds / (3600 * 24));
    const hours = Math.floor((timeDifferenceInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
    const seconds = timeDifferenceInSeconds % 60;

    const formattedTimeDifference = `${days} day${
      days !== 1 ? "s" : ""
    } ${hours} hour${hours !== 1 ? "s" : ""} ${minutes} min${
      minutes !== 1 ? "s" : ""
    } ${seconds} sec${seconds !== 1 ? "s" : ""} ago`;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const givenDateFormatted = `${givenDateTime.getDate()} ${
      months[givenDateTime.getMonth()]
    } ${givenDateTime.getFullYear()}, ${padZero(
      givenDateTime.getHours()
    )}:${padZero(givenDateTime.getMinutes())}:${padZero(
      givenDateTime.getSeconds()
    )} +00:00 UTC`;

    const result = ` ${formattedTimeDifference} at ${givenDateFormatted}`;

    return result;
  }
  function padZero(num) {
    return num.toString().padStart(2, "0");
  }

  const handleAppStatusClose = () => {
    setAppStatus(false);
  };

  const buildApkPopupOpen = (type) => {
    if (type === "prod") {
      if (!prodDataConfig?.platform) {
        setGoogleAppIdPopUp(true);
      } else {
      }
      if (!resData?.googleAppId) {
        setGoogleAppIdPopUp(true);
      } else if (resData?.googleAppId && !prodDataConfig?.platform) {
        setGoogleAppIdPopUp(true);
      } else {
        if (!resData?.isProdApkExist) {
          setBuildApkPopUp(true);
        } else {
          setBuildApk(true);
        }
      }
    } else {
      if (!devDataConfig?.platform) {
        setGoogleAppIdPopUp(true);
      } else {
        setBuildApk(true);
      }
    }
  };
  const handleBuildApkOpen = () => {
    setBuildApkPopUp(false);
    setBuildApk(true);
  };
  const handleBuildHistoryOpen = () => {
    setBuildHistory(true);
  };
  const handleBuildHistoryClose = () => {
    setBuildHistory(false);
  };

  const buildApkPopUpClose = () => {
    setBuildApkPopUp(false);
  };

  const googleAppIdPopUpClose = () => {
    setGoogleAppIdPopUp(false);
  };
  const handleBuildApkClose = () => {
    setBuildApk(false);
    setBuildApkLoad(false);
    setBuildApkDisable(false);
    setUserError({
      ...userError,
      versionCode: false,
      versionCodeMsg: "",
      versionName: false,
      versionNameMsg: "",
      comments: false,
      commentsMsg: "",
      distributionEmail: false,
      distributionEmailMsg: "",
      packageSuffix: false,
      packageSuffixMsg: "",
      buildTypes: false,
      buildTypesMsg: "",
    });
    setUserData({
      ...userData,
      versionCode: "",
      versionName: "",
      comments: "",
      distributionEmail: "",
      packageSuffix: "",
      buildTypes: "",
    });
  };
  const getConfig = (e) => {
    setConfigPopUp(true);
  };

  const handleLoading = (data) => {
    setLoading(data);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("applicationPopUp");
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };
  const ApplicationPopUp = () => {
    if (applicationPopUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleDataChange = (newData) => {
    setLoadData(newData);
  };

  const handleTabClick = (index) => {
    setType(index === 1 ? "PROD" : "DEV");
    if (index === 1) {
      let payload = {
        applicationId: selectedAppId,
        deploymentType: "PROD",
      };
      setLoad(true);

      Services.prodAppExist(payload)
        .then((res) => {
          setLoad(false);
          setDeploymentIdExist(res?.data.status);
        })
        .catch((err) => {});
    } else if (index === 0) {
      setDeploymentIdExist();
    }
  };
  const handleDataFromChild = (data) => {
    setValue(data);
    handleTabClick(data);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBuildApkChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "versionCode") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          versionCode: true,
          versionCodeMsg: "This field is required",
        });
      } else {
        if (versionCodeRegex.test(e.target.value) === false) {
          setUserError({
            ...userError,
            versionCode: true,
            versionCodeMsg: "Invalid Format",
          });
        } else {
          setUserError({
            ...userError,
            versionCode: false,
            versionCodeMsg: "",
          });
        }
      }
    } else if (e.target.name === "versionName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          versionName: true,
          versionNameMsg: "This field is required",
        });
      } else {
        if (versionNameRegex.test(e.target.value) === false) {
          setUserError({
            ...userError,
            versionName: true,
            versionNameMsg: "Invalid Format",
          });
        } else {
          setUserError({
            ...userError,
            versionName: false,
            versionNameMsg: "",
          });
        }
      }
    } else if (e.target.name === "buildTypes") {
      if (e.target.value === "") {
        setUserError({
          ...userError,
          buildTypes: true,
          buildTypesMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          buildTypes: false,
          buildTypesMsg: "",
        });
      }
    } else if (e.target.name === "packageSuffix") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          packageSuffix: true,
          packageSuffixMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          packageSuffix: false,
          packageSuffixMsg: "",
        });
      }
    } else if (e.target.name === "comments") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          comments: true,
          commentsMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          comments: false,
          commentsMsg: "",
        });
      }
    }
    setUserData(newData);
  };
  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.versionCode === "") {
      userErrors.versionCode = true;
      userErrors.versionCodeMsg = "This field is required";
      count++;
    } else {
      if (versionCodeRegex.test(userData?.versionCode) === false) {
        userErrors.versionCode = true;
        userErrors.versionCodeMsg = "Invalid Format";
        count++;
      } else {
        userErrors.versionCode = false;
        userErrors.versionCodeMsg = "";
      }
    }
    if (data?.packageSuffix === "") {
      userErrors.packageSuffix = true;
      userErrors.packageSuffixMsg = "This field is required";
      count++;
    } else {
      userErrors.packageSuffix = false;
      userErrors.packageSuffixMsg = "";
    }
    if (data?.buildTypes === "") {
      userErrors.buildTypes = true;
      userErrors.buildTypesMsg = "This field is required";
      count++;
    } else {
      userErrors.buildTypes = false;
      userErrors.buildTypesMsg = "";
    }
    if (data?.versionName === "") {
      userErrors.versionName = true;
      userErrors.versionNameMsg = "This field is required";
      count++;
    } else {
      if (versionNameRegex.test(userData?.versionName) === false) {
        userErrors.versionName = true;
        userErrors.versionNameMsg = "Invalid Format";
        count++;
      } else {
        userErrors.versionName = false;
        userErrors.versionNameMsg = "";
      }
    }
    if (data?.comments === "") {
      userErrors.comments = true;
      userErrors.commentsMsg = "This field is required";
      count++;
    } else {
      userErrors.comments = false;
      userErrors.commentsMsg = "";
    }
    setUserError(userErrors);
    return count;
  };
  const handleBuildApkSave = () => {
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setBuildApkLoad(true);
      setBuildApkDisable(true);
      let payload = {
        versionCode: userData?.versionCode,
        versionName: userData?.versionName,
        comments: userData?.comments,
        emailDistribution: userData?.distributionEmail,
        buildTypes: userData?.buildTypes,
        packageSuffix: userData?.packageSuffix,
        applicationId: selectedAppId,
        deploymentType: type,
        deploymentId: deploymentId,
        configId:
          type === "DEV"
            ? resData?.devConfigId
              ? resData?.devConfigId
              : ""
            : resData?.ProdConfigId
            ? resData?.ProdConfigId
            : "",
      };
      Services.createBuildApk(payload)
        .then((res) => {
          if (res?.status === 200) {
            setBuildApkLoad(false);
            setBuildApkDisable(false);
            if (res?.data?.status === "success") {
              handleBuildApkClose();
              showSuccess(res?.data?.message);
            } else if (res?.data?.status === "failed") {
              showError(res?.data?.message);
            }
          }
          getApplicationDetails();
        })
        .catch((err) => {});
    }
  };
  const [appDetailApi, setAppDetailApi] = useState();

  const appDetailsRefresh = (data) => {
    setAppDetailApi(data);
  };
  const tld = process.env.REACT_APP_TLD;

  const publishApp = () => {
    if (
      customerData?.parentCompany?.[2]?.status === "New" ||
      customerData?.parentCompany?.[3]?.status === "New"
    ) {
      setOpenWarning(true);
    } else {
      setDisablePublishApp(true);
      setLoadPublishApp(true);
      Services.publishApp({ appId: appId })
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.status === "success") {
              setDisablePublishApp(false);
              setLoadPublishApp(false);
              showSuccess(WarningMessages?.getAppStatus);
            } else {
              setDisablePublishApp(false);
              setLoadPublishApp(false);
              showError(res?.data?.message);
            }
            getApplicationDetails();
          }
        })
        .catch((err) => {});
    }
  };

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const [devDataConfig, setDevDataConfig] = useState("");
  const [prodDataConfig, setProdDataConfig] = useState("");
  const handleDevData = (data) => {
    setDevDataConfig(data);
  };
  const handleProdData = (data) => {
    setProdDataConfig(data);
  };

  useEffect(() => {
    if (appDetailApi) {
      getApplicationDetails();
    }
    // eslint-disable-next-line
  }, [appDetailApi]);

  useEffect(() => {
    getApplicationDetails();
    ApplicationPopUp();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getApplicationDetails();
    setLoadData(false);
    //eslint-disable-next-line
  }, [loadData, type]);
  const prodXmlAssistant = () => {
    if (applicationData?.data?.prodEntryPointUrl) {
      window.open(
        `http://validator.${tld}/validate/gui?url=${applicationData?.data?.prodEntryPointUrl}`,
        "_blank"
      );
    }
  };
  const devXmlAssistant = () => {
    if (applicationData?.data?.devEntryPointUrl) {
      window.open(
        `http://validator.${tld}/validate/gui?url=${applicationData?.data?.devEntryPointUrl}`,
        "_blank"
      );
    }
  };
  return (
    <CookiesProvider>
      <Layout>
        <Container maxWidth="xxl">
          <Divider
            sx={{
              backgroundColor: "#0094CD",
              borderRadius: "6px",
              marginTop: "5px",
              borderBottomWidth: 2,
            }}
          ></Divider>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
                loading={loading.toString()}
              />
            ) : null}
          </Box>

          <Box
            component={"span"}
            sx={{
              color: "#5A5656",
              fontWeight: "400",
              lineHeight: "42.61px",
              width: "300px",
              height: "18px",
              padding: "0px 0px 15px 0px",
              fontSize: { xs: "10px", md: "12px" },
            }}
          >
            {customerData?.parentCompany && !loading ? (
              <BreadCrumb value={customerData} other="Application" />
            ) : (
              ""
            )}
          </Box>
          <BasicDetails
            productType={productType}
            applicationDetails={applicationData}
            sendLoadingStatus={handleLoading}
            onDataUpdate={handleDataChange}
            loadData={loadData}
          />

          <Box sx={{ width: "100%", marginTop: "30px" }}>
            <Box>
              <Tabs
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#01537A",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={tabStyle}
                  style={{
                    marginRight: "2px",
                  }}
                  label="Development"
                  {...a11yProps(0)}
                  onClick={() => handleTabClick(0)}
                />
                <Tab
                  icon={
                    value === 1 ? (
                      <DoneIcon
                        sx={{
                          color: "white",
                          fontSize: "23px",
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  sx={tabStyle}
                  style={{
                    marginRight: "2px",
                  }}
                  iconPosition="start"
                  label="Production"
                  {...a11yProps(1)}
                  onClick={() => handleTabClick(1)}
                />
                <Tab
                  icon={
                    value === 2 ? (
                      <DoneIcon
                        sx={{
                          color: "white",
                          fontSize: "23px",
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  sx={tabStyle}
                  style={{
                    marginRight: "2px",
                  }}
                  iconPosition="start"
                  label="Change Log"
                  {...a11yProps(2)}
                  onClick={() => handleTabClick(2)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              {!resData?.checkPublishAppDev ? (
                ""
              ) : (
                <Box
                  sx={{
                    background: "#01537A",
                    border: "1px solid #01537A ",
                    marginTop: "0px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  You have unpublished changes. Please remember to publish your
                  changes to see the effect of your changes.
                </Box>
              )}

              <Box
                sx={{
                  background: "rgba(1, 83, 122, 0.1)",
                }}
              >
                {!productType ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      padding: "20px",
                      width: {
                        sm: "619px",
                        md: "auto",
                      },

                      justifyContent: {
                        xs: "space-between",
                        sm: "space-between",
                        md: "space-between",
                      },
                      display: {
                        xs: productType === "#datafree WRAP" ? "grid" : "flex",
                        md: "flex",
                      },
                      gridTemplateColumns: {
                        xs: "auto",
                        sm: "repeat(2, 1fr)",
                      },
                      gap: "6px",
                    }}
                  >
                    {productType !== "#datafree WRAP" && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        {productType !== "#datafree WRAP" && (
                          <Button
                            onClick={() => {
                              handleAppStatusOpen("dev");
                            }}
                            className={
                              accessPermissionFields() || disableGetAppStatus
                                ? "disable"
                                : null
                            }
                            sx={{
                              textTransform: "capitalize",
                              background: "white!important",
                              fontSize: {
                                xs:
                                  !resData?.prodAppId &&
                                  productType !== "#datafree WRAP"
                                    ? "12px"
                                    : "14px",
                                md: "14px",
                              },
                              width: {
                                xs:
                                  !resData?.prodAppId &&
                                  productType !== "#datafree WRAP"
                                    ? "120px"
                                    : "fit-content",
                                md: "fit-content",
                              },
                              height: "30px",
                              marginRight: "700px",
                              color: accessPermissionFields()
                                ? "#373737"
                                : "#01537A",
                              border: accessPermissionFields()
                                ? "1px solid #373737!important"
                                : "1px solid #01537A!important",
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                Get App status
                              </span>
                              {statusLoading ? (
                                <Loader
                                  type={"spinningBubbles"}
                                  color={"#01537A"}
                                  height={20}
                                  width={20}
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Button>
                        )}
                      </Box>
                    )}

                    {productType === "#datafree WRAP" && (
                      <>
                        <Box
                          sx={{
                            display: {
                              xs:
                                productType === "#datafree WRAP"
                                  ? "grid"
                                  : "flex",
                              md: "flex",
                            },
                            // marginRight: "300px",
                            gap: "15px",
                            gridTemplateColumns: {
                              xs: "auto",
                              sm: "repeat(3, 1fr)",
                            },
                          }}
                        >
                          <Button
                            onClick={() => {
                              handleAppStatusOpen("dev");
                            }}
                            className={
                              accessPermissionFields() || disableGetAppStatus
                                ? "disable"
                                : null
                            }
                            sx={{
                              textTransform: "capitalize",
                              background: "white!important",
                              fontSize: "14px",
                              height: "30px",
                              width: "fit-content",
                              color: accessPermissionFields()
                                ? "#373737"
                                : "#01537A",
                              border: accessPermissionFields()
                                ? "1px solid #373737!important"
                                : "1px solid #01537A!important",
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                Get App status
                              </span>
                              {statusLoading ? (
                                <Loader
                                  type={"spinningBubbles"}
                                  color={"#01537A"}
                                  height={20}
                                  width={20}
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Button>
                        </Box>
                      </>
                    )}

                    <Box
                      sx={{
                        display: {
                          xs:
                            productType === "#datafree WRAP" ? "grid" : "flex",
                          md: "flex",
                        },
                        gap: "15px",
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(3, 1fr)",
                        },
                      }}
                    >
                      <Button
                        onClick={handleBuildHistoryOpen}
                        className={
                          accessPermissionFields() || !resData?.isDevApkExist
                            ? "disable"
                            : null
                        }
                        sx={{
                          textTransform: "capitalize",
                          background: "white!important",
                          fontSize: "14px",
                          height: "30px",
                          width: "fit-content",
                          color: accessPermissionFields()
                            ? "#373737"
                            : !resData?.isDevApkExist
                            ? " #757373"
                            : "#01537A",
                          border: accessPermissionFields()
                            ? "1px solid #373737!important"
                            : !resData?.isDevApkExist
                            ? "1px solid #757373!important"
                            : "1px solid #01537A!important",
                        }}
                        variant="outlined"
                      >
                        Build History and download
                      </Button>
                      {productType === "#datafree WRAP" && (
                        <Button
                          onClick={devXmlAssistant}
                          className={
                            accessPermissionFields() ? "disable" : null
                          }
                          sx={{
                            textTransform: "capitalize",
                            background: "white!important",
                            fontSize: "14px",
                            height: "30px",
                            width: "fit-content",
                            color: "#373737",
                            border: "1px solid #373737!important",
                          }}
                        >
                          XML Assistant
                        </Button>
                      )}
                      {productType === "#datafree WRAP" && (
                        <Button
                          onClick={() => {
                            buildApkPopupOpen("dev");
                          }}
                          className={
                            accessPermissionFields() || !resData?.devAppId
                              ? "disable"
                              : null
                          }
                          sx={{
                            textTransform: "capitalize",
                            background: "white!important",
                            fontSize: "14px",
                            height: "30px",
                            width: "fit-content",
                            color: accessPermissionFields()
                              ? "#373737"
                              : !resData?.devAppId
                              ? " #757373"
                              : "#01537A",
                            border: accessPermissionFields()
                              ? "1px solid #373737!important"
                              : !resData?.devAppId
                              ? "1px solid #757373!important"
                              : "1px solid #01537A!important",
                          }}
                          variant="outlined"
                        >
                          Build APK
                        </Button>
                      )}

                      <Button
                        onClick={publishApp}
                        className={
                          accessPermissionFields() ||
                          !resData?.checkPublishAppDev ||
                          disablePublishApp
                            ? "disable"
                            : null
                        }
                        sx={{
                          textTransform: "capitalize",
                          fontSize: {
                            xs:
                              !resData?.prodAppId &&
                              productType !== "#datafree WRAP"
                                ? "12px"
                                : "14px",
                            md: "14px",
                          },
                          width: {
                            xs:
                              !resData?.prodAppId &&
                              productType !== "#datafree WRAP"
                                ? "160px"
                                : "fit-content",
                            md: "fit-content",
                          },
                          height: "30px",

                          border: accessPermissionFields()
                            ? "1px solid #373737!important"
                            : !resData?.checkPublishAppDev
                            ? "1px solid #757373 !important"
                            : "1px solid #01537A!important",
                          background: accessPermissionFields()
                            ? "#373737!important"
                            : !resData?.checkPublishAppDev
                            ? "#757373 !important"
                            : "#01537A  !important",
                        }}
                        startIcon={<LoopIcon />}
                        variant="contained"
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <span style={{ marginRight: "5px" }}>
                            Publish App
                          </span>
                          {loadPublishApp ? (
                            <Loader
                              type={"spinningBubbles"}
                              color={"white"}
                              height={20}
                              width={20}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Button>

                      <Button
                        className={
                          accessPermissionFields() || !resData?.devAppId
                            ? "disable"
                            : null
                        }
                        href={`https://qamonitor.${tld}/login/${resData?.devAppId}/new`}
                        target="dfworkbench"
                        sx={{
                          height: "30px",
                          textTransform: "capitalize",
                          fontSize: {
                            xs:
                              !resData?.prodAppId &&
                              productType !== "#datafree WRAP"
                                ? "12px"
                                : "14px",
                            md: "14px",
                          },
                          width: {
                            xs:
                              !resData?.prodAppId &&
                              productType !== "#datafree WRAP"
                                ? "180px"
                                : "fit-content",
                            md: "fit-content",
                          },

                          border: accessPermissionFields()
                            ? "1px solid #373737!important"
                            : !resData?.devAppId
                            ? "1px solid #757373!important"
                            : "1px solid #01537A!important",
                          background: accessPermissionFields()
                            ? "#373737"
                            : !resData?.devAppId
                            ? "#757373!important"
                            : "#01537A!important",
                        }}
                        variant="contained"
                        startIcon={
                          <img
                            src={workBench}
                            alt="workBench"
                            width="15px"
                            height="15px"
                          />
                        }
                      >
                        Launch Workbench
                      </Button>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",

                    gap: "3px",
                    padding: "20px",
                  }}
                >
                  <VersionPanel
                    applicationDetails={applicationData}
                    appId={appId}
                  />
                  {productType === "#datafree REACH" ? (
                    <ReachDevelopment
                      applicationDetails={applicationData}
                      refreshApi={refreshApi}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : productType === "#datafree WRAP" ? (
                    <WrapDevelopment
                      applicationDetails={applicationData}
                      refreshApi={refreshApi}
                      apiRefresh={appDetailsRefresh}
                      sendDataToDetailPage={handleDevData}
                    />
                  ) : productType === "#datafree SWITCH" ? (
                    <SwitchDevelopment
                      applicationDetails={applicationData}
                      refreshApi={refreshApi}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {!resData?.checkPublishAppProd ? (
                ""
              ) : (
                <Box
                  sx={{
                    background: "#01537A",
                    border: "1px solid #01537A ",
                    marginTop: "0px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  You have unpublished changes. Please remember to publish your
                  changes to see the effect of your changes.
                </Box>
              )}
              <Box
                sx={{
                  background: "rgba(1, 83, 122, 0.1)",

                  width: "100%",
                }}
              >
                {!productType ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      padding: "20px",
                      width: {
                        sm: "619px",
                        md: "auto",
                      },
                      justifyContent: {
                        xs: "space-between",

                        sm: "space-between",
                        md: "space-between",
                      },
                      display: {
                        xs: productType === "#datafree WRAP" ? "grid" : "flex",
                        md: "flex",
                      },
                      gap: "6px",
                    }}
                  >
                    {productType !== "#datafree WRAP" && (
                      <>
                        <Button
                          onClick={() => {
                            handleAppStatusOpen("prod");
                          }}
                          className={
                            accessPermissionFields() ||
                            disableGetAppStatus ||
                            !resData?.prodAppId
                              ? "disable"
                              : null
                          }
                          sx={{
                            textTransform: "capitalize",
                            background: "white!important",
                            fontSize: "14px",
                            height: "30px",
                            color: accessPermissionFields()
                              ? "#373737"
                              : !resData?.prodAppId
                              ? "#757373"
                              : "#01537A",
                            border: accessPermissionFields()
                              ? "1px solid #373737!important"
                              : !resData?.prodAppId
                              ? "1px solid  #757373!important"
                              : "1px solid #01537A!important",
                          }}
                          variant="outlined"
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <span style={{ marginRight: "5px" }}>
                              Get App status
                            </span>
                            {statusLoading ? (
                              <Loader
                                type={"spinningBubbles"}
                                color={"#01537A"}
                                height={20}
                                width={20}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        </Button>
                      </>
                    )}

                    {productType === "#datafree WRAP" && (
                      <>
                        <Box
                          sx={{
                            display: {
                              xs:
                                productType === "#datafree WRAP"
                                  ? "grid"
                                  : "flex",
                              md: "flex",
                            },

                            gap: "15px",
                            gridTemplateColumns: {
                              xs: "auto",
                              sm: "repeat(3, 1fr)",
                            },
                          }}
                        >
                          <Button
                            onClick={() => {
                              handleAppStatusOpen("prod");
                            }}
                            className={
                              accessPermissionFields() ||
                              disableGetAppStatus ||
                              !resData?.checkPublishAppProd
                                ? "disable"
                                : null
                            }
                            sx={{
                              textTransform: "capitalize",
                              background: "white!important",
                              fontSize: "14px",
                              height: "30px",
                              width: "fit-content",
                              color: accessPermissionFields()
                                ? "#373737"
                                : !resData?.checkPublishAppProd
                                ? " #757373"
                                : "#01537A",
                              border: accessPermissionFields()
                                ? "1px solid #373737!important"
                                : !resData?.checkPublishAppProd
                                ? "1px solid  #757373!important"
                                : "1px solid #01537A!important",
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                Get App status
                              </span>
                              {statusLoading ? (
                                <Loader
                                  type={"spinningBubbles"}
                                  color={"#01537A"}
                                  height={20}
                                  width={20}
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Button>

                          <Button
                            onClick={handleBuildHistoryOpen}
                            className={
                              accessPermissionFields() ||
                              !resData?.isProdApkExist
                                ? "disable"
                                : null
                            }
                            sx={{
                              textTransform: "capitalize",
                              background: "white!important",
                              fontSize: "14px",
                              height: "30px",
                              width: "fit-content",
                              color: accessPermissionFields()
                                ? "#373737"
                                : !resData?.isProdApkExist
                                ? " #757373"
                                : "#01537A",
                              border: accessPermissionFields()
                                ? "1px solid #373737!important"
                                : !resData?.isProdApkExist
                                ? "1px solid  #757373!important"
                                : "1px solid #01537A!important",
                            }}
                            variant="outlined"
                          >
                            Build History and download
                          </Button>
                        </Box>
                      </>
                    )}
                    <Box
                      sx={{
                        display: {
                          xs:
                            productType === "#datafree WRAP" ? "grid" : "flex",
                          md: "flex",
                        },
                        gap: "15px",
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(3, 1fr)",
                        },
                      }}
                    >
                      {productType === "#datafree WRAP" && (
                        <Button
                          onClick={prodXmlAssistant}
                          className={
                            accessPermissionFields() ? "disable" : null
                          }
                          sx={{
                            textTransform: "capitalize",
                            background: "white!important",
                            fontSize: "14px",
                            height: "30px",
                            width: "fit-content",
                            color: "#373737",
                            border: "1px solid #373737!important",
                          }}
                        >
                          XML Assistant
                        </Button>
                      )}
                      {productType === "#datafree WRAP" && (
                        <>
                          <Button
                            onClick={() => {
                              buildApkPopupOpen("prod");
                            }}
                            className={
                              accessPermissionFields() || !resData?.prodAppId
                                ? "disable"
                                : null
                            }
                            sx={{
                              textTransform: "capitalize",
                              background: "white!important",
                              width: "fit-content",
                              fontSize: "14px",
                              height: "30px",
                              color: accessPermissionFields()
                                ? "#373737"
                                : !resData?.prodAppId
                                ? " #757373"
                                : "#01537A",
                              border: accessPermissionFields()
                                ? "1px solid #373737!important"
                                : !resData?.prodAppId
                                ? "1px solid #757373!important"
                                : "1px solid #01537A!important",
                            }}
                            variant="outlined"
                          >
                            Build APK
                          </Button>
                        </>
                      )}

                      <Button
                        onClick={publishApp}
                        className={
                          accessPermissionFields() ||
                          !resData?.checkPublishAppProd ||
                          disablePublishApp
                            ? "disable"
                            : null
                        }
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          height: "30px",
                          width: "fit-content",
                          border: accessPermissionFields()
                            ? "1px solid #373737!important"
                            : !resData?.checkPublishAppProd
                            ? "1px solid #757373 !important"
                            : "1px solid #01537A!important",
                          background: accessPermissionFields()
                            ? "#373737!important"
                            : !resData?.checkPublishAppProd
                            ? "#757373 !important"
                            : "#01537A  !important",
                        }}
                        startIcon={<LoopIcon />}
                        variant="contained"
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <span style={{ marginRight: "5px" }}>
                            Publish App
                          </span>
                          {loadPublishApp ? (
                            <Loader
                              type={"spinningBubbles"}
                              color={"white"}
                              height={20}
                              width={20}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Button>

                      <Button
                        className={
                          accessPermissionFields() || !resData?.prodAppId
                            ? "disable"
                            : null
                        }
                        href={`https://qamonitor.${tld}/login/${resData?.prodAppId}/new`}
                        target="dfworkbench"
                        sx={{
                          height: "30px",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          border: accessPermissionFields()
                            ? "1px solid #373737!important"
                            : !resData?.prodAppId
                            ? "1px solid #757373!important"
                            : "1px solid #01537A!important",
                          background: accessPermissionFields()
                            ? "#373737"
                            : !resData?.prodAppId
                            ? "#757373 !important"
                            : "#01537A !important",
                        }}
                        variant="contained"
                        startIcon={
                          <img
                            src={workBench}
                            alt="workBench"
                            width="15px"
                            height="15px"
                          />
                        }
                      >
                        Launch Workbench
                      </Button>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    gap: "3px",
                    padding: "20px",
                  }}
                >
                  <VersionPanel
                    applicationDetails={applicationData}
                    appId={appId}
                  />
                  {productType === "#datafree REACH" && deploymentIdExist ? (
                    <ReachProduction
                      applicationDetails={applicationData}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : productType === "#datafree WRAP" && deploymentIdExist ? (
                    <WrapProduction
                      applicationDetails={applicationData}
                      apiRefresh={appDetailsRefresh}
                      sendDataToDetailPage={handleProdData}
                    />
                  ) : productType === "#datafree SWITCH" &&
                    deploymentIdExist ? (
                    <SwitchProduction
                      applicationDetails={applicationData}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : !deploymentIdExist && load ? (
                    <Container maxWidth="xxl">
                      <Box
                        sx={{
                          width: "100%",
                          height: "188px",
                          background: "white",
                          maxWidth: {
                            xs: "210px",
                            sm: "420px",
                            md: "initial",
                            lg: "initial",
                            xl: "initial",
                          },
                          padding: "20px 37px 20px 41px ",
                        }}
                      >
                        {load ? (
                          <Box
                            sx={{ justifyContent: "center", display: "flex" }}
                          >
                            <Loader
                              type={"spokes"}
                              color={"#01537A"}
                              height={40}
                              width={40}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </Container>
                  ) : (
                    <Container maxWidth="xxl">
                      <Box
                        sx={{
                          width: "100%",
                          height: "188px",
                          background: "white",
                          maxWidth: {
                            xs: "210px",
                            sm: "420px",
                            md: "initial",
                            lg: "initial",
                            xl: "initial",
                            textAlign: "center",
                          },
                          padding: "20px 37px 20px 41px ",
                        }}
                      >
                        <Typography sx={{ fontSize: "20px", color: "#01537A" }}>
                          You do not have a production version of this
                          application yet.
                          <br />
                          Register a Production version of the application first
                        </Typography>
                        <Button
                          className={
                            accessPermissionFields() ? "disable" : null
                          }
                          onClick={getCloneConfig}
                          sx={{
                            width: "fit-content",
                            background: accessPermissionFields()
                              ? "#373737"
                              : "#01537A !important",
                            padding: "5px 40px 5px 40px",
                            textTransform: "none",
                            marginTop: "40px",
                          }}
                          variant="contained"
                        >
                          Create Production Version
                        </Button>
                      </Box>
                    </Container>
                  )}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container
                maxWidth="xxl"
                sx={{
                  background: "rgba(1, 83, 122, 0.1)",
                  padding: "40px 20px",
                }}
              >
                <ChangeLog tab={value} />
              </Container>
            </TabPanel>
            <Box
              sx={{
                width: "100%",
                paddingBottom: "20px",
                paddingRight: "40px",
                display: "flex",
                justifyContent: "flex-end",
                background: "rgba(1, 83, 122, 0.1)",
              }}
            >
              {!productType ? (
                ""
              ) : value === 0 ? (
                <Button
                  className={accessPermissionFields() ? "disable" : null}
                  onClick={getCloneConfig}
                  sx={{
                    width: "fit-content",
                    background: accessPermissionFields()
                      ? "#373737"
                      : "#01537A !important",
                    padding: "5px 40px 5px 40px",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Create Production Version
                </Button>
              ) : (
                // ) : value === 1 && deploymentIdExist ? (
                //   <Button
                //     className={accessPermissionFields() ? "disable" : null}
                //     onClick={getConfig}
                //     sx={{
                //       width: "fit-content",
                //       background: accessPermissionFields()
                //         ? "#373737"
                //         : "#01537A !important",
                //       padding: "5px 40px 5px 40px",
                //       textTransform: "none",
                //     }}
                //     variant="contained"
                //   >
                //     Clone Configuration to Dev
                //   </Button>
                ""
              )}
            </Box>
          </Box>
        </Container>
        {configPopUp && (
          <CloneConfigDev
            open={configPopUp}
            handleClose={handleConfigPopUp}
            productType={productType}
            onDataFromChild={handleDataFromChild}
          />
        )}

        {cloneConfigPopUp && (
          <CloneConfigPopUp
            open={cloneConfigPopUp}
            handleClose={handleCloneConfigPopUp}
            productType={productType}
            onDataFromChild={handleDataFromChild}
          />
        )}

        {buildApk && (
          <BuildApk
            buildApk={buildApk}
            handleBuildApkChange={handleBuildApkChange}
            handleBuildApkClose={handleBuildApkClose}
            handleBuildApkSave={handleBuildApkSave}
            userData={userData}
            userError={userError}
            type={type}
            configId={
              type === "DEV"
                ? resData?.devConfigId
                  ? resData?.devConfigId
                  : ""
                : resData?.ProdConfigId
                ? resData?.ProdConfigId
                : ""
            }
            setUserData={setUserData}
            setDeploymentId={getDeploymentId}
            load={buildApKLoad}
            disableButton={buildApkDisable}
          />
        )}

        {appStatus && (
          <GetAppStatus
            appStatus={appStatus}
            getAppStatusData={getAppStatusData}
            timeInWords={timeInWords}
            statusLoading={statusLoading}
            handleAppStatusClose={handleAppStatusClose}
          />
        )}

        {buildApkPopUp && (
          <BuildApkPopUp
            buildApkPopUp={buildApkPopUp}
            buildApkPopUpClose={buildApkPopUpClose}
            handleBuildApkOpen={handleBuildApkOpen}
          />
        )}

        {googleAppIdPopUp && (
          <GoogleAppIdPopUp
            googleAppIdPopUp={googleAppIdPopUp}
            googleAppIdPopUpClose={googleAppIdPopUpClose}
            platform={
              type === "DEV"
                ? devDataConfig?.platform
                  ? devDataConfig?.platform
                  : ""
                : prodDataConfig?.platform
                ? prodDataConfig?.platform
                : ""
            }
            googleAppId={
              type === "DEV"
                ? true
                : resData?.googleAppId
                ? resData?.googleAppId
                : ""
            }
          />
        )}
        {buildHistory && (
          <BuildHistory
            buildHistory={buildHistory}
            deploymentId={
              type === "DEV"
                ? resData?.devDeploymentId
                  ? resData?.devDeploymentId
                  : ""
                : resData?.prodDeploymentId
                ? resData?.prodDeploymentId
                : ""
            }
            handleBuildHistoryClose={handleBuildHistoryClose}
          />
        )}

        <Dialog maxWidth="xl" open={open} onClose={handleClose}>
          <DialogTitle
            sx={{
              background: "linear-gradient(to right bottom, #005075, #018EC5)",
              color: "#FFFFFF",
              fontWeight: "400",
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            Complete your App setup
            <CloseOutlinedIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                fontSize: "17px",
                position: "absolute",
                right: "8px",
                top: "8px",
              }}
            />
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                marginTop: "20px",
                paddingLeft: "60px",
                paddingRight: "60px",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: "400",
                  fontSize: "24px",
                  marginBottom: "30px",
                }}
              >
                Complete your Datafree App{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                Launch the workbench to complete your Application details
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  textTransform: "capitalize !important",
                  color: "#01537A",
                  borderColor: "#01537A !important",
                  padding: "5px 15px ",
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  href={`https://qamonitor.${tld}/login/${resData?.devAppId}/new`}
                  target="dfworkbench"
                  variant="contained"
                  sx={{
                    textTransform: "capitalize !important",
                    background: "#01537A !important",
                    borderColor: "#01537A !important",
                    padding: "5px 15px 5px 15px",
                  }}
                >
                  Launch Workbench
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog open={openWorkBench} onClose={handleWorkBenchClose}>
          <DialogTitle
            sx={{
              background: "linear-gradient(to right bottom, #005075, #018EC5)",
              color: "#FFFFFF",
              fontWeight: "400",
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            Launch Workbench{" "}
            <CloseOutlinedIcon
              onClick={handleWorkBenchClose}
              sx={{
                cursor: "pointer",
                fontSize: "17px",
                position: "absolute",
                right: "8px",
                top: "8px",
              }}
            />
          </DialogTitle>
          <DialogContent
            sx={{
              marginTop: "20px",
              fontWeight: "400",
              fontSize: "18px",
              paddingLeft: "60px",
              paddingRight: "60px",
            }}
          >
            Which workbench environment would you like to launch?
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                href={`https://qamonitor.${tld}/login/${resData?.devAppId}/new`}
                target="dfworkbench"
                sx={{
                  textTransform: "capitalize !important",
                  color: "#01537A",
                  borderColor: "#01537A !important",
                  padding: "5px 15px ",
                }}
                variant="outlined"
              >
                Development
              </Button>
              <Button
                href={`https://qamonitor.${tld}/login/${resData?.prodAppId}/new`}
                target="dfworkbench"
                variant="contained"
                sx={{
                  textTransform: "capitalize !important",
                  background: "#01537A !important",
                  borderColor: "#01537A !important",
                  padding: "5px 15px 5px 15px",
                }}
              >
                Production
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {openWarning && (
          <ConfirmationPopup
            open={openWarning}
            handleClose={handleWarningClose}
            message={errorMsg}
            type={"error"}
          />
        )}
      </Layout>
    </CookiesProvider>
  );
}
