export const API_URL = process.env.REACT_APP_API_URL + "/api";

export const authRequests = {
  login: "/login",
  resetPassword: "v1/change-password",
  logout: "/logout",
};

export const endPoints = {
  adminIndex: "/v1/dashboard",
  searchList: "/v1/search",
  graphView: "/v1/data-usages",
  getCompanyList: "/v1/companies",
  getChangeLog: "/v1/change-logs",
  getServiceTypes: "/v1/service-types",
  getSupportedProducts: "/v1/service/products",
  licenseeusers: "/v1/users",
  licenseeservices: "/v1/licensee/services",
  licenseechannels: "/v1/licensee/channels",
  publisherList: "/v1/channel/publishers",
  updatePublisher: "/v1/companies/",
  licenseeGatewayDomain: "/v1/licensee/gateway-domains",
  addLicenseeService: "/v1/create/licensee-service",
  updateCompanyDetails: "/v1/companies/",
  applications: "/v1/publisher/applications",
  updateApplication: "/v1/applications/",
  bulkUpdation: "/v1/application/status-change",
  dataUsageGraph: "/v1/publisher-data-usages",
  accountSettings: "/v1/company-details",
  checkCompanyName: "/v1/company/exist",
  updateUser: "/v1/user/",
  updateLicenseeService: "/v1/licensee-service/",
  updateChannel: "/v1/licensee/channel/",
  updateGateway: "/v1/gateway-domains",
  uploadLogo: "/v1/change-logo-image",
  removeLogo: "/v1/remove-logo-image",
  resetPasswordRequest: "/v1/password-reset-request",
  userBulkUpdation: "/v1/status-change/user",
  companyStatusUpdation: "/v1/status-change/company",
  licenseeCompanyList: "/v1/licensee/companies",
  channelCompanyList: "/v1/channel/companies",
  channelListById: "/v1/licensee/channels",
  companySearch: "/v1/company/search",
  createUser: "/v1/create/user",
  breadCrumbList: "/v1/parent",
  userDetails: "/v1/user-details",
  addNewRole: "/v1/add/role/",
  createCompany: "/v1/create/company",
  checkLicenseeShortCode: "/v1/licensee-shortcode/exist",
  roleStatusChange: "/v1/status-change/user",
  owningCompanySearch: "/v1/owing-company/search",
  uploadUserImage: "/v1/user-image/upload",
  removeUserImage: "/v1/user-image/remove",
  userRoles: "/v1/user-roles",
  swtichDasboard: "/v1/user/login-check",
  getAccessToken: "/get-access-token",
  getUserCompany: "/v1/user-companies",
  createApplication: "/v1/create/application",
  getApplicationDetails: "/v1/application",
  reachTabDetails: "/v1/add/reach/security-rules ",
  getSecurityTabDetails: "/v1/security-rules",
  getReachChangeLog: "/v1/application/change-log",
  uploadReachIcon: "/v1/change-icon-image",
  removeReachIcon: "/v1/remove-app-icon",
  changeAppDetails: "/v1/applications",
  getGatewayDomain: "/v1/get-gateway-domains",
  gatewayDomainExists: "/v1/reach/domain/exist",
  uploadSplashImage: "/v1/change-splash-image",
  checkApplicationName: "/v1/application-name/exist",
  checkReachApp:"v1/validate/appId",
  removeSplashImg: "/v1/remove-splash-image",
  licenseeServiceBulkUpdate: "/v1/status-change/licensee-service",
  cloneApplication: "/v1/application-clone",
  getWrapApkConfig: "/v1/apk-configuration",
  getWrapLookFeel: "/v1/style-management",
  updateWrapLookFeel: "/v1/style-management",
  clientVersionsList: "/v1/client-version",
  buildTypesList: "/v1/build-types",
  getBuildApk: "/v1/build-details",
  createBuildApk: "/v1/generate-apk",
  getWrapEntryPoint: "/v1/entry-point",
  updateWrapApkConfig: "/v1/apkconfiguration",
  updateWrapSecurityRules: "/v1/securityrules",
  validateXML: "/v1/validate/config-xml",
  prodAppExist: "/v1/prod-app/exist",
  updateEntryPoint: "/v1/update-entry-point",
  deleteApplication: "/v1/delete-application",
  deleteAppVersion: "/v1/delete-app/version",
  getBuildHistory: "/v1/build-history",
  getAppStatus: "/v1/get-app-status",
  publishApp: "/v1/publish-application",
  auditLog: "/v1/user-audit-log",
  activityLog: "/v1/activity",
  getHeaderData: "/v1/user-company",
  prodAppList: "/v1/prod-app-list",
  createReport: "/v1/create/scheduled-report",
  getReportDetails: "/v1/scheduleReport",
  deleteReport: "/v1/delete/schedule-report/",
  publisherCompany: "/v1/publisher/companies",
  getNewReport: "/v1/report/datausage",
  getMonthlySummaryReport: "/v1/report/monthly-summary",
  getScheduleReport: "/v1/schedule-report",
  updateScheduleReport: "/v1/update/schedule-report",
  topApps: "/v1/topApps",
};
