import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import Services from "../../api/services";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../../components/loader";
import {
  statusContext,
  switchStatusContext,
} from "../../context/serviceContext";

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const textStyle = {
  fontWeight: 500,
  color: "#0094CD",
  fontSize: "14px",
  width: "68px",
  height: "15px",
};
const textStyle3 = {
  fontWeight: 400,
  color: "#231F20",

  fontSize: "14px",
  width: "284px",
  height: "15px",
  textOverflow: "clip",
  whiteSpace: "break-spaces",
};

export default function ChangeLogPanel({ tab }) {
  let navigate = useNavigate();
  const switchDashs = (item) => {
    if (item.changeType === "Account") {
      const userRole = localStorage.getItem("role");
      const type = item?.type ?? item?.companyType ?? "";
      switch (true) {
        case type === "BINU" && userRole === "ROLE_ADMIN":
          return true;
        case type === "LICENSEE" &&
          (userRole === "ROLE_LICENSEE" || userRole === "ROLE_ADMIN"):
          return true;
        case type === "CHANNEL" &&
          (userRole === "ROLE_LICENSEE" ||
            userRole === "ROLE_ADMIN" ||
            userRole === "ROLE_CHANNEL"):
          return true;
        case (type === "CUSTOMER" || type === "RESELLER") &&
          (userRole === "ROLE_LICENSEE" ||
            userRole === "ROLE_ADMIN" ||
            userRole === "ROLE_CHANNEL" ||
            userRole === "ROLE_USER"):
          return true;

        default:
          return false;
      }
    }
    return false;
  };

  const location = useLocation();
  const clickedAppId = location.state.clickedAppId;
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const switchDash = (item) => {
    if (item.changeType === "Account") {
      const userRole = localStorage.getItem("role");
      const type = item?.type ?? item?.companyType ?? "";

      if (type === "BINU" && userRole === "ROLE_ADMIN") {
        navigate("/");
        return;
      }
      if (
        type === "LICENSEE" &&
        (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
      ) {
        if (userRole === "ROLE_ADMIN") {
          localStorage.setItem("licenseeId", item?.companyId);
          window.location.href = `/licensee/${item?.companyId}`;
        } else {
          navigate("/");
        }
        return;
      }
      if (
        type === "CHANNEL" &&
        (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
      ) {
        window.location.href = `/channel/${item?.companyId}`;
        localStorage.setItem("channelId", item?.companyId);
        return;
      } else if (userRole === "ROLE_CHANNEL") {
        navigate("/");
        return;
      }
      if (
        (type === "CUSTOMER" || type === "RESELLER") &&
        (userRole === "ROLE_ADMIN" ||
          userRole === "ROLE_LICENSEE" ||
          userRole === "ROLE_CHANNEL")
      ) {
        window.location.href = `/customer/${item?.companyId}`;
        localStorage.setItem("publisherId", item?.companyId);
        return;
      } else if (userRole === "ROLE_USER") {
        navigate("/");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  let params = useParams();

  const usersId = localStorage.getItem("userid");

  async function getChangeLogs() {
    setLoading(true);
    let payload = "";

    payload = { applicationId: clickedAppId, userId: usersId };
    Services.getReachChangeLog(payload)
      .then((res) => {
        if (res?.status === 200) {
          setResData(res?.data.logDetails);
          setLoading(false);
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getChangeLogs();
    //eslint-disable-next-line
  }, []);

  const tableHeadings = [
    {
      field: "name",
      headerName: "Made by",
      width: 200,
      headerAlign: "left",
      align: "left",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.editorId,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={textStyle}>
              {params?.row?.editorBy !== null ? params?.row?.editorBy : "Liz"}
            </span>
          </div>
        );
      },
    },
    {
      field: "change",
      headerName: "Change",
      minWidth: 220,
      headerAlign: "left",
      align: "left",
      flex: 0.1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.description,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>{params.row.description}</span>
          </div>
        );
      },
    },
    {
      field: "changeLocation",
      headerName: "Change Location",
      width: 300,
      headerAlign: "left",
      align: "left",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            onClick={() => switchDash(params.row)}
            style={{
              cursor: switchDashs(params.row) ? "pointer" : "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>{params.row.companyName}</span>
          </div>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 170,
      headerAlign: "left",
      align: "left",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.date,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>
              {moment(params.row.date).format("DD MMM YYYY, HH:mm")}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Container
        maxWidth="xxl"
        style={{
          padding: "0",
        }}
      >
        {resData && resData?.length !== 0 && !loading ? (
          <Box
            sx={{
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor:
                  switchStatusContextVal && statusContextVal
                    ? "#373737"
                    : "#01537A !important",
                color: "white",
              },
            }}
          >
            <DataTable
              tab={tab}
              rows={resData}
              columns={tableHeadings}
              size={resData.length}
              tableName="ChangeLogPanel"
              loading={loading}
            />
          </Box>
        ) : resData?.length === 0 && !loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
              justifyContent: "center",
              marginTop: "1rem",
              color: "#5A5656",
            }}
          >
            No change log details to show
          </Box>
        ) : (
          <Container maxWidth="xxl">
            <Box
              sx={{
                width: "100%",
                height: "188px",
                background: "white",
                maxWidth: {
                  xs: "210px",
                  sm: "420px",
                  md: "initial",
                  lg: "initial",
                  xl: "initial",
                },
                padding: "20px 37px 20px 41px ",
              }}
            >
              {loading ? (
                <Box sx={{ margin: "0 auto", width: "40px" }}>
                  <Loader
                    type={"spokes"}
                    color={"#01537A"}
                    height={40}
                    width={40}
                    loading={loading.toString()}
                  />
                </Box>
              ) : null}
            </Box>
          </Container>
        )}
      </Container>
    </Box>
  );
}
