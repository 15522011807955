import {
  Container,
  Paper,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import Layout from "../../components/layout";
import {
  Box,
  Divider,
  Button,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import defaultUserImg from "../../assets/img/defaultUserImg.png";
import CreateIcon from "@mui/icons-material/Create";
import Services from "../../api/services";
import BreadCrumb from "../../components/breadCrumb";
import Loader from "../../components/loader";
import DoneIcon from "@mui/icons-material/Done";
import defaultImg from "../../assets/img/noImage.png";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";

export default function ChannelAddCompany() {
  const { showSuccess, showError } = useToast();
  let regExp =
    /^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const [userImage, setUserImage] = useState(defaultUserImg);
  const [userData, setUserData] = useState({
    companyStatus: "New",
    companyName: "",
    userName: "",
    email: "",
    companyId: localStorage.getItem("companyId"),
  });
  const [userError, setUserError] = useState({
    companyName: false,
    companyMsg: "",
    userName: false,
    userMsg: "",
    email: "",
    emailMsg: "",
    owningCompanyMsg: "",
    owningCompany: false,
  });
  const [addUserClick, setAddUserClick] = useState(false);
  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.companyName === "") {
      userErrors.companyName = true;
      userErrors.companyMsg = "This field is required";
      count++;
    } else {
      if (checkCompanyVal === true) {
        userErrors.companyName = true;
        userErrors.companyMsg = "Company name already exists";
        count++;
      } else {
        userErrors.companyName = false;
        userErrors.companyMsg = "";
      }
    }
    if (data?.userName === "" && isCreateUserOpen) {
      userErrors.userName = true;
      userErrors.userMsg = "This field is required";
      count++;
    } else {
      userErrors.userName = false;
      userErrors.userMsg = "";
    }
    if (data?.email === "" && isCreateUserOpen) {
      userErrors.email = true;
      userErrors.emailMsg = "This field is required";
      count++;
    } else if (regExp.test(data?.email) === false && isCreateUserOpen) {
      userErrors.email = true;
      userErrors.emailMsg = "Enter a valid Email ID";
      count++;
    } else {
      userErrors.email = false;
      userErrors.emailMsg = "";
    }

    setUserError(userErrors);
    return count;
  };


  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  const inputRef1 = useRef(null);
  const handleClickUser = () => {
    inputRef1.current.click();
  };
  let navigate = useNavigate();
  const handleFileChange = (e) => {
    const copyData = { ...userData };
    if (e.target.name === "companyImage") {
      copyData["companyImage"] = e.target.files[0];
      setImgSrc(URL.createObjectURL(e.target.files[0]));
    } else {
      copyData["userImage"] = e.target.files[0];
      setUserImage(URL.createObjectURL(e.target.files[0]));
    }
    setUserData(copyData);
  };
  const [load, setLoad] = useState(false);
  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setLoad(true);
      setAddUserClick(true);
      formData.append("editorId", localStorage.getItem("userid"));
      formData.append("companyName", userData["companyName"]);
      formData.append("status", userData["companyStatus"]);
      if (userData["userName"]) {
        formData.append("userName", userData["userName"]);
      }
      if (userData["email"]) {
        formData.append("email", userData["email"]);
      }
      formData.append("parentCompanyId", userData["companyId"]);
      formData.append("companyType", type);
      if (userData?.userImage) {
        formData.append(
          "userImage",
          userData["userImage"] ? userData["userImage"] : ""
        );
      }
      if (userData?.companyImage) {
        formData.append(
          "companyLogo",
          userData["companyImage"] ? userData["companyImage"] : ""
        );
      }

      Services.createCompany(formData)
        .then((res) => {
          setLoad(false);
          setAddUserClick(false);
          if (res.data.status === "failed") {
            showError(res.data.message);
          } else {
            showSuccess(res.data.message);
            if (type === "customer") {
              localStorage.setItem("customerId", res?.data?.companyId);
              localStorage.setItem("publisherId", res?.data?.companyId);
              navigate("/customer/" + res?.data?.companyId);
            }
          }
        })
        .catch((err) => {
          setAddUserClick(false);
        });
    }
  };

  const handleClear = (e) => {
    setUserError({
      companyName: false,
      companyMsg: "",
      userName: false,
      userMsg: "",
      email: "",
      emailMsg: "",
      owningCompany: false,
      owningCompanyMsg: "",
    });
    setUserData({
      companyStatus: "New",
      companyName: "",
      userName: "",
      email: "",
      companyId: localStorage.getItem("companyId"),
    });
    setImgSrc(defaultImg);
    setUserImage(defaultUserImg);
    setType("customer");
    setIsChecked(false);
    setIsCreateUserOpen(false);
    setLoad(false);
    setAddUserClick(false);
  };
  const [checkCompanyVal, setCheckCompanyVal] = useState();
  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "companyName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          companyName: true,
          companyMsg: "This field is required",
        });
      } else if (e.target.value !== "") {
        Services.checkCompanyName({ name: e.target.value })
          .then((res) => {
            setCheckCompanyVal(res.data.status);
            if (res.data.status === true) {
              setUserError({
                ...userError,
                companyName: true,
                companyMsg: "Company name already exists",
              });
            } else if (
              e.target.value === "" ||
              e.target.value.match(/^ *$/) !== null
            ) {
              setUserError({
                ...userError,
                companyName: true,
                companyMsg: "This field is required",
              });
            } else {
              setUserError({
                ...userError,
                companyName: false,
                companyMsg: "",
              });
            }
          })
          .catch((err) => {
          });
      } else {
        setUserError({
          ...userError,
          companyName: false,
          companyMsg: "",
        });
      }
    }
    if (e.target.name === "userName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          userName: true,
          userMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          userName: false,
          userMsg: "",
        });
      }
    } else if (e.target.name === "email") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          email: true,
          emailMsg: "This field is required",
        });
      } else {
        if (regExp.test(e.target.value) === false) {
          setUserError({
            ...userError,
            email: true,
            emailMsg: "Enter a valid Email ID",
          });
        } else {
          setUserError({
            ...userError,
            email: false,
            emailMsg: "",
          });
        }
      }
    }
    setUserData(newData);
  };
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("customer");
  const [breadCrumbData, setBreadCrumbData] = useState();
  const getBreadCrumbList = () => {
    setLoading(true);
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
        setLoading(false);
      })
      .catch((err) => {

      });
  };

  const changeCompanyStatus = (typeValue) => {
    setUserData({
      ...userData,
      companyStatus: typeValue,
    });
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);
  const handleUserCreate = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setIsCreateUserOpen(false);
      setUserData({
        ...userData,
        userName: "",
        email: "",
      });

      setUserImage(defaultUserImg);
      setUserError({
        ...userError,
        userName: false,
        userMsg: "",
        email: "",
        emailMsg: "",
      });
    } else {
      setUserError({
        owningCompany: false,
        owningCompanyMsg: "",
      });
      setIsCreateUserOpen(true);
    }
  };

  useEffect(() => {
    getBreadCrumbList();
  }, []);
  return (
    <CookiesProvider>
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="2px"
        >
          {loading ? (
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
              loading={loading.toString()}
            />
          ) : null}
        </Box>
        <Box sx={{ padding: "0px 0px 15px 25px" }}>
          {breadCrumbData?.parentCompany ? (
            <BreadCrumb value={breadCrumbData} other="Add Company" />
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ padding: "10px 0px 30px 0px", marginTop: "15px" }}>
          <Container maxWidth="xxl">
            <Box
              sx={{
                boxShadow: 2,
                padding: "20px 0px 20px 56px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  lineHeight: "30px",
                  color: "#01537A",
                  fontSize: "33px",
                }}
              >
                Add Customer
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "#0094CD",
                  borderRadius: "6px",
                  marginTop: "20px",
                  borderBottomWidth: 2,
                  marginBottom: "20px",
                }}
              ></Divider>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: {
                    xs: "100%",
                    sm: "500px",
                    md: "550px",
                    lg: "740px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "30px",
                    color: "#01537A",
                    marginTop: "20px",
                  }}
                >
                  Customer Details
                </Typography>
                <Divider
                  sx={{
                    borderRadius: "6px",
                    borderBottomWidth: 1,
                    marginBottom: "20px",
                  }}
                ></Divider>

                <Box
                  sx={{
                    display: "flex",
                    gap: "27px",
                    marginBottom: "50px",
                    flexDirection: { xs: "column", md: "row" },
                    rowGap: 2,
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "125px",
                        height: "125px",
                        textAlign: "center",
                        fontSize: "0.875rem",
                        fontWeight: "700",
                        marginBottom: "10px",
                      }}
                    >
                      <Paper
                        style={{
                          background: "#fff",
                          width: "125px",
                          height: "125px",
                          borderRadius: "14PX",
                          color: "#01537A",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          padding: "6px 6px",
                        }}
                      >
                        <img
                          style={{ borderRadius: "50%", padding: "2px 2px" }}
                          alt="Datafree"
                          src={imgSrc === null ? defaultImg : imgSrc}
                          rounded="lg"
                          width="115px"
                          height="115px"
                        />
                      </Paper>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        name="companyImage"
                        onChange={(e) => handleFileChange(e)}
                      />

                      <IconButton
                        onClick={handleClick}
                        style={{
                          position: "absolute",
                          backgroundColor: "#01537A",
                          color: "#FFFFFF",
                          width: "22.71px",
                          height: "22.71px",
                          fontSize: "15px",
                          right: "12px",
                          bottom: "15px",
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <CreateIcon fontSize="11px" />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        color: "#01537A",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      * optional
                    </Typography>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { xs: "400px", md: "565px" },
                      width: "100%",
                      rowGap: "7px",
                      marginTop: "5px",
                    }}
                    autoComplete="off"
                  >
                    <TextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      value={userData?.companyName}
                      error={!!userError?.companyName}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                      name="companyName"
                      variant="standard"
                      helperText={
                        userError?.companyName ? userError?.companyMsg : ""
                      }
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      Company Name
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color: "#1B2935",
                        }}
                      >
                        Status
                      </Typography>
                      <ButtonGroup
                        aria-label="outlined button group"
                        sx={{
                          width: {
                            xs: "397px",
                            sm: "400px",
                            md: "560px",
                            lg: "565px",
                            xl: "560px",
                          },
                          height: { xs: "35px", lg: "40px" },
                          fontWeight: 400,
                        }}
                      >
                        <Button
                          onClick={() => {
                            changeCompanyStatus("New");
                          }}
                          startIcon={
                            userData?.companyStatus === "New" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={
                            userData?.companyStatus === "New"
                              ? "btn-active"
                              : "btn-nonactive"
                          }
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                        >
                          New
                        </Button>
                        <Button
                          onClick={() => {
                            changeCompanyStatus("Approved");
                          }}
                          startIcon={
                            userData?.companyStatus === "Approved" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={
                            userData?.companyStatus === "Approved"
                              ? "btn-active"
                              : "btn-nonactive"
                          }
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                        >
                          Approved
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    borderRadius: "6px",
                    borderBottomWidth: 1,
                    marginBottom: "20px",
                  }}
                ></Divider>
                <Box sx={{ marginBottom: "20px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} />}
                    label="Optionally create user?"
                    onChange={handleUserCreate}
                    sx={{ fontSize: "16px" }}
                  />
                </Box>

                {isCreateUserOpen && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "30px",
                        color: "#01537A",
                        marginTop: "20px",
                      }}
                    >
                      User Details
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "27px",
                        marginBottom: "50px",
                        flexDirection: { xs: "column", md: "row" },
                        rowGap: 2,
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            position: "relative",
                            width: "125px",
                            height: "125px",
                            textAlign: "center",
                            fontSize: "0.875rem",
                            fontWeight: "700",
                            marginBottom: "10px",
                          }}
                        >
                          <Paper
                            style={{
                              background: "#fff",
                              width: "125px",
                              height: "125px",
                              borderRadius: "14PX",
                              color: "#01537A",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              padding: "6px 6px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                padding: "2px 2px",
                              }}
                              alt="Datafree"
                              src={
                                userImage === null ? defaultUserImg : userImage
                              }
                              rounded="lg"
                              width="110px"
                              height="110px"
                            />
                          </Paper>
                          <input
                            style={{ display: "none" }}
                            ref={inputRef1}
                            type="file"
                            name="userImage"
                            onChange={(e) => handleFileChange(e)}
                          />

                          <IconButton
                            onClick={handleClickUser}
                            style={{
                              position: "absolute",
                              backgroundColor: "#01537A",
                              color: "#FFFFFF",
                              width: "22.71px",
                              height: "22.71px",
                              fontSize: "15px",
                              right: "10px",
                              bottom: "18px",
                            }}
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <CreateIcon fontSize="11px" />
                          </IconButton>
                        </Box>
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "24px",
                          }}
                        >
                          * optional
                        </Typography>
                      </Box>

                      <Box
                        component="form"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: { xs: "400px", md: "565px" },
                          width: "100%",
                          rowGap: "7px",
                          marginTop: "5px",
                        }}
                        autoComplete="off"
                      >
                        <TextField
                          required
                          inputProps={{
                            style: {
                              fontSize: 16,
                              fontWeight: 400,
                              letterSpacing: 0.5,
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                          }}
                          inputlabelprops={{
                            style: { fontSize: 16, fontWeight: 400 },
                          }}
                          value={userData?.userName}
                          error={!!userError?.userName}
                          onChange={handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              </InputAdornment>
                            ),
                          }}
                          name="userName"
                          variant="standard"
                          helperText={
                            userError?.userName ? userError?.userMsg : ""
                          }
                        />
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            letterSpacing: "0.4px",
                            color: "#01537A",
                          }}
                        >
                          User Name
                        </Typography>
                        <TextField
                          required
                          inputProps={{
                            style: {
                              fontSize: 16,
                              fontWeight: 400,
                              letterSpacing: 0.5,
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                          }}
                          inputlabelprops={{
                            style: { fontSize: 16, fontWeight: 400 },
                          }}
                          value={userData?.email}
                          error={!!userError?.email}
                          onChange={handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              </InputAdornment>
                            ),
                          }}
                          name="email"
                          variant="standard"
                          helperText={
                            userError?.email ? userError?.emailMsg : ""
                          }
                        />
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            letterSpacing: "0.4px",
                            color: "#01537A",
                          }}
                        >
                          Email
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 30px 0px 0px",
                  }}
                >
                  <Button
                    className="cancel-btn"
                    variant="outlined"
                    onClick={handleClear}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={handleSave}
                    disabled={addUserClick}
                    sx={{
                      width: "200px",
                      height: "40px",
                      fontWeight: "400",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>
                        Register Company
                      </span>
                      {load ? (
                        <Loader
                          type={"spinningBubbles"}
                          color={"white"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Layout>
    </CookiesProvider>
  );
}
