import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";

export default function AppDefnition({ publicAddress }) {
  const [healthcheckStatus, setHealthcheckStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch("https://xminds.com/?callback=handleResponse", { mode: "no-cors" })
    //   .then((response) => console.log(response))
    //   .catch((error) => console.error("Error:", error));
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://xminds.com/?callback=handleResponse"
        ); // Replace with your public URL

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("result", result);
        // setData(result);
      } catch (error) {
        // setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   if (!publicAddress) {
  //     return;
  //   }

  //   const checkWebsite = async (attempt = 1) => {
  //     try {
  //       const fullUrl = publicAddress.startsWith("http")
  //         ? publicAddress
  //         : `https://${publicAddress}`;

  //       const corsProxy = `https://cors-anywhere.herokuapp.com/`;
  //       const response = await fetch(`${corsProxy}${fullUrl}`);

  //       if (response.status === 200) {
  //         setHealthcheckStatus(true);
  //       } else {
  //         throw new Error(`Unexpected response status: ${response.status}`);
  //       }
  //     } catch (error) {
  //       console.log(`Attempt ${attempt}: error detected - ${error.message}`);
  //       if (attempt < 3) {
  //         checkWebsite(attempt + 1); // Retry up to 3 times
  //       } else {
  //         setHealthcheckStatus("error");
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   checkWebsite();
  // }, [publicAddress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "120px", sm: "140px", md: "160px" },
        height: { xs: "100px", sm: "110px", md: "120px" },
        alignItems: "center",
        backgroundColor: "#E0F7FA",
        borderRadius: 2,
        boxShadow: 1,
        marginLeft: { xs: "auto", sm: "150px", md: "300px" },
        marginRight: { xs: "auto", sm: "initial", md: "initial" },
        padding: { xs: "8px", sm: "12px", md: "16px" },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "12px", sm: "14px", md: "16px" },
          textAlign: "center",
        }}
      >
        D-Direct Service
        <br />
        Health Check
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 1,
        }}
      >
        <CheckCircleIcon
          sx={{
            color: healthcheckStatus === true ? "green" : "gray",
            fontSize: { xs: 30, sm: 35, md: 40 },
            opacity: healthcheckStatus === true ? 1 : 0.5,
            marginRight: "10px",
          }}
        />
        <CancelIcon
          sx={{
            color: healthcheckStatus === "error" ? "red" : "gray",
            fontSize: { xs: 30, sm: 35, md: 40 },
            opacity: healthcheckStatus === "error" ? 1 : 0.5,
            marginLeft: "10px",
          }}
        />
      </Box>
    </Box>
  );
}
