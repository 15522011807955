import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import Box from "@mui/material/Box";
import {
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import Services from "../../api/services";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/loader";
import PaginationHeader from "./paginationHeader";
import PaginationRounded from "../pagination";
import {
  statusContext,
  switchStatusContext,
} from "../../context/serviceContext";

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const textStyle = {
  fontWeight: 500,
  color: "#0094CD",
  fontSize: "14px",
  width: "68px",
  height: "15px",
};

const textStyle3 = {
  fontWeight: 400,
  color: "#231F20",
  fontSize: "14px",
  width: "284px",
  height: "15px",
  textOverflow: "clip",
  whiteSpace: "break-spaces",
};

export default function ChangeLogPanel({ tab, type }) {
  let navigate = useNavigate();
  const switchDashs = (item) => {
    if (item.changeType === "Account") {
      const userRole = localStorage.getItem("role");
      const type = item?.type ?? item?.companyType ?? "";
      switch (true) {
        case type === "BINU" && userRole === "ROLE_ADMIN":
          return true;
        case type === "LICENSEE" &&
          (userRole === "ROLE_LICENSEE" || userRole === "ROLE_ADMIN"):
          return true;
        case type === "CHANNEL" &&
          (userRole === "ROLE_LICENSEE" ||
            userRole === "ROLE_ADMIN" ||
            userRole === "ROLE_CHANNEL"):
          return true;
        case (type === "CUSTOMER" || type === "RESELLER") &&
          (userRole === "ROLE_LICENSEE" ||
            userRole === "ROLE_ADMIN" ||
            userRole === "ROLE_CHANNEL" ||
            userRole === "ROLE_USER"):
          return true;

        default:
          return false;
      }
    }
    return false;
  };

  const switchDash = (item) => {
    if (item.changeType === "Account") {
      const userRole = localStorage.getItem("role");
      const type = item?.type ?? item?.companyType ?? "";

      if (type === "BINU" && userRole === "ROLE_ADMIN") {
        navigate("/");
        return;
      }
      if (
        type === "LICENSEE" &&
        (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
      ) {
        if (userRole === "ROLE_ADMIN") {
          localStorage.setItem("licenseeId", item?.companyId);
          window.location.href = `/licensee/${item?.companyId}`;
        } else {
          navigate("/");
        }
        return;
      }
      if (
        type === "CHANNEL" &&
        (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
      ) {
        window.location.href = `/channel/${item?.companyId}`;
        localStorage.setItem("channelId", item?.companyId);
        return;
      } else if (userRole === "ROLE_CHANNEL") {
        navigate("/");
        return;
      }
      if (
        (type === "CUSTOMER" || type === "RESELLER") &&
        (userRole === "ROLE_ADMIN" ||
          userRole === "ROLE_LICENSEE" ||
          userRole === "ROLE_CHANNEL")
      ) {
        window.location.href = `/customer/${item?.companyId}`;
        localStorage.setItem("publisherId", item?.companyId);
        return;
      } else if (userRole === "ROLE_USER") {
        navigate("/");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  const location = useLocation();
  const clickedUserId = location.state?.clickedUserId;
  const clickedCompId = location.state?.clickedCompId;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [totalCount, setTotalCount] = useState(0);

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  async function getChangeLogs() {
    setLoading(true);
    let payload = "";

    switch (true) {
      case clickedCompId !== undefined:
        payload = { companyId: clickedCompId, type: "company" };
        Services.getChangeLog(payload, page, size)
          .then((res) => {
            if (res?.status === 200) {
              setResData(res?.data?.logDetails);
              setLoading(false);
              setTotalCount(res?.data?.totalCount);
            }
          })
          .catch((err) => {});
        break;

      case clickedUserId !== undefined && type !== "userDetailLog":
        payload = { userId: clickedUserId };
        Services.ActivityLog(payload, page, size)
          .then((res) => {
            if (res?.data?.status) {
              setResData(res?.data?.data?.logDetails);
              setLoading(false);
              setTotalCount(res?.data?.data?.totalCount);
            }
          })
          .catch((err) => {});
        break;

      case clickedUserId !== undefined && type === "userDetailLog":
        payload = { userId: clickedUserId, type: "user" };
        Services.getChangeLog(payload, page, size)
          .then((res) => {
            if (res?.status === 200) {
              setResData(res?.data.logDetails);
              setLoading(false);
              setTotalCount(res?.data.totalCount);
            }
          })
          .catch((err) => {});
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    getChangeLogs();
    //eslint-disable-next-line
  }, [page, size]);

  const getColumnVisibility = (type) => {
    const visibility = {
      change: true,
      changeType: type !== "userDetailLog",
      changeLocation: type !== "userDetailLog",
      time: true,
    };
    return visibility;
  };

  const visibile = getColumnVisibility(type);

  const tableHeadings = [
    {
      flex: 1,
      field: "change",
      headerName: "Item",
      minWidth: 220,
      headerAlign: "left",
      align: "left",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.description,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>{params.row.description}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "changeType",
      headerClassName: "super-app-theme--header",
      headerName: "Change Type",
      width: 190,
      hide: type === "userDetailLog",
      headerAlign: "left",
      align: "left",
      valueGetter: (value, row) => ({
        vals: row.changeType,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>{params.row.changeType}</span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "changeLocation",
      headerName: "Enitity",
      width: 300,
      headerClassName: "super-app-theme--header",
      hide: type === "userDetailLog",
      headerAlign: "left",
      align: "left",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <button
            type="button"
            onClick={() => switchDash(params.row)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                switchDash(params.row);
              }
            }}
            style={{
              cursor: switchDashs(params.row) ? "pointer" : "default",
              display: "flex",
              background: "none",
              border: "none",
              paddingTop: 15,
            }}
            onTouchStart={() => switchDash(params.row)}
          >
            <span style={textStyle}>{params.row.name}</span>
          </button>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 170,
      flex: 1,
      headerAlign: "left",
      align: "left",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.date,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "default",
              display: "flex",
            }}
          >
            <span style={textStyle3}>
              {moment(params.row.date).format("DD MMM YYYY, HH:mm")}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Container
        maxWidth="xxl"
        style={{
          padding: "0",
        }}
      >
        {resData && !loading ? (
          <>
            {totalCount !== 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  pb: "5px",
                  pr: "2px",
                }}
              >
                <PaginationHeader
                  page={page}
                  size={size}
                  dataLength={resData?.length}
                  totalCount={totalCount}
                />
              </Box>
            ) : null}

            {totalCount !== 0 ? (
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor:
                      switchStatusContextVal && statusContextVal
                        ? "#373737"
                        : "#01537A !important",
                    color: "white",
                  },
                }}
              >
                <DataTable
                  tab={tab}
                  rows={resData}
                  columns={tableHeadings}
                  size={resData?.length}
                  tableName="ChangeLogPanel"
                  loading={loading}
                  columnVisibilityModel={visibile}
                />
              </Box>
            ) : (
              <Box
                sx={{ textAlign: "center", color: "#5A5656", fontSize: "16px" }}
              >
                No details to display
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
          </Box>
        )}

        {!loading && totalCount >= 10 ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "#01537A" }}
            >
              Items per page :{" "}
            </Typography>
            <FormControl variant="standard">
              <Select
                value={size}
                sx={{
                  width: "55px",
                  margin: "0px 10px 0px 5px",
                  backgroundColor: "#8080802b",
                  color: "#000",
                  borderBottom: "1px solid #01537A",
                  textAlign: "center",
                  "&:after": {
                    borderBottom: "1.5px solid #01537A",
                  },
                  "&hover": {
                    borderBottom: "1px solid #01537A",
                  },
                }}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
                <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
                <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
                <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
              </Select>
            </FormControl>
            <PaginationRounded
              changeHandle={changePagination}
              page={page + 1}
              Count={totalCount}
              size={size}
            />
          </Box>
        ) : null}
      </Container>
    </Box>
  );
}
