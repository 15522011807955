import Layout from "../../components/layout";
import Dashboard from "../../components/licenseeDashboard";
import { CookiesProvider } from "react-cookie";
import ChannelDashboard from "../../components/channelDashboard";
import UserDashboard from "../../components/publisherDashboard";
export default function Licensee(){
  const path = window.location.pathname.split('/');
  let role = ""
  if(path.length === 3){
    role = path[1];
    localStorage.setItem('switchRole', role)
  }
  else {
    localStorage.removeItem('switchRole')
  }
    return(
        <CookiesProvider>
        <Layout>
        {role === "channel" ? (
          <ChannelDashboard />
        ) : role === "customer" ? (
          <UserDashboard />
        ) : (
          <Dashboard />
        )}
        </Layout>
        </CookiesProvider>
    )
}