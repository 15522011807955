import React, { useContext, useEffect, useState } from "react";
import { Box, Card, Typography, Avatar } from "@mui/material";
import dataFreeLogo from "../assets/img/dataFreeLogo.svg";
import { CardContent } from "@mui/material";
import AvatarLogo from "../assets/img/noImage.png";
import Container from "@mui/material/Container";
import {
  statusDispatchContext,
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
} from "../context/serviceContext";
import Services from "../api/services";

export default function ListRoles() {
  let switchStatusDispatchContextVal = useContext(switchStatusDispatchContext);
  let statusDispatchContextVal = useContext(statusDispatchContext);
  let archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );

  const API_URL = process.env.REACT_APP_API_URL;
  const [roles, setRoles] = useState([]);

  const switchRole = (companyId, role, status, userName, userId) => {
    Services.auditLog({ companyId: companyId })
      .then((res) => {
        localStorage.setItem("role", role);
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("username", userName);
        localStorage.setItem("userid", userId);
        if (status === "Suspended") {
          localStorage.setItem("status", true);
          localStorage.setItem("switchStatus", true);
          localStorage.setItem("archivedStatus", false);
          switchStatusDispatchContextVal(true);
          statusDispatchContextVal(true);
          archivedStatusDispatchContextVal(false);
        } else {
          localStorage.setItem("status", false);
          localStorage.setItem("switchStatus", false);
          localStorage.setItem("archivedStatus", false);
          switchStatusDispatchContextVal(false);
          statusDispatchContextVal(false);
          archivedStatusDispatchContextVal(false);
        }
        window.location.href = "/";
      })
      .catch((err) => {});
  };
  const getUserRoles = async () => {
    const userCompanyRes = await Services.getUserCompany();
    setRoles(userCompanyRes.data);
  };

  useEffect(() => {
    getUserRoles();
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          height: "auto",
          width: { xs: "400px", md: "700px" },
          margin: "auto",
          marginTop: "5%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            width: "100%",
            background: "#fff",
          }}
        >
          <CardContent>
            <Box
              align="center"
              autoComplete="off"
              sx={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="Datafree"
                  height="87px"
                  width="300px"
                  src={dataFreeLogo}
                />
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  width: "254px",
                  margin: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "900",
                    lineHeight: "24px",
                    color: "#373737",
                    fontSize: "19px",
                  }}
                >
                  Which account are you signing into?
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "23px",
                  justifyContent: "space-between",
                  display: "grid",

                  gridTemplateColumns: {
                    xs: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                  },
                  gap: {
                    xs: "2rem",
                    md: "3rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                }}
              >
                {roles && roles.length > 0
                  ? roles.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "auto",
                          height: "auto",
                          border: "1px solid #000000",
                          borderRadius: "12px",
                          background: "#F2F2F2",
                          cursor:
                            item?.companyStatus !== "Archived"
                              ? "pointer"
                              : "default",
                          padding: "13px 8px",
                        }}
                        onClick={
                          item?.companyStatus !== "Archived"
                            ? () =>
                                switchRole(
                                  item?.companyId,
                                  item.role,
                                  item?.companyStatus,
                                  item?.userName,
                                  item?.userId
                                )
                            : null
                        }
                      >
                        <Box
                          sx={{
                            background: "white",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "50px",
                            width: "67px",
                            height: "67px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            alt="Datafree"
                            src={
                              item?.logo !== null
                                ? API_URL + item?.logo
                                : AvatarLogo
                            }
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: " #23262F",
                            marginTop: "25px",
                          }}
                        >
                          {item.companyName}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: " #757373",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.role === "user"
                            ? "Customer"
                            : item.role.toLowerCase().split("_")[1]}{" "}
                          User
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: " #757373",
                            textTransform: "capitalize",
                          }}
                        >
                          <span style={{ fontSize: "14px", color: "#373737" }}>
                            Status:
                          </span>{" "}
                          {item?.companyStatus}
                        </Typography>
                      </Box>
                    ))
                  : ""}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
