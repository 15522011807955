import React, { useState, useContext } from "react";
import {
  ListItemText,
  ListItemAvatar,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import licenseeLogo from "../../assets/img/icon1.png";
import appLogo from "../../assets/img/Icons2.svg";
import channelLogo from "../../assets/img/Icons4.svg";
import userLogo from "../../assets/img/Icons8.svg";
import customerLogo from "../../assets/img/Icons7.svg";
import dataIcon from "../../assets/img/dataIcon.svg";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DataTable from "../../components/dataTable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  gridNumberComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import moment from "moment";
import useResponsive from "../../components/useResponsive";
import { useNavigate } from "react-router-dom";
import {
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
  userRoleDispatchContext,
  statusContext,
} from "../../context/serviceContext";
import { useBreadCrumbUserData } from "../../context/breadCrumbContext";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
    height: "35px",
    textAlign: "center",
    padding: "9px",
    backgroundColor: "#DFDFDF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
  },
}));

export default function SearchList(props) {
  const statusContextVal = JSON.parse(useContext(statusContext));
  let switchStatusContextVal = useContext(switchStatusDispatchContext);
  let archivedStatusContextVal = useContext(archivedStatusDispatchContext);
  const userRoleDispatchContextVal = useContext(userRoleDispatchContext);
  const [isHovered, setIsHovered] = useState(false);
  const largeDev = useResponsive("up", "xl");
  let gridView = props.viewMode;
  let tab = props.currentTab;
  let navigate = useNavigate();
  const idComparator = (v1, v2, param1, param2) => {
    return gridNumberComparator(v1.Id, v2.Id, param1, param2);
  };
  const { updateBreadCrumbData } = useBreadCrumbUserData();
  const nameComparator = (v1, v2, param1, param2) => {
    if (v1.vals !== "" && v2.vals !== "") {
      return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
    }
  };

  const colStyle = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const colStyleName = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: "12px",
    marginTop: "4px",
    width: "200px",
  };

  const getProdActivity = (item) => {
    const newItem = item.filter((val) => val.deploymentType === "PROD");
    if (newItem.length > 0) {
      return (
        <>
          {item.map((val, i) => (
            <span key={i}>
              {val?.deploymentType === "PROD"
                ? val?.lastUpdateDate
                  ? moment(val?.lastUpdateDate).format("DD MMM YYYY, hh:mm")
                  : "N/A"
                : ""}
            </span>
          ))}
        </>
      );
    } else {
      return "N/A";
    }
  };

  const getProdActivitys = (item) => {
    const newItem = item.filter((val) => val.deploymentType === "PROD");
    if (newItem.length > 0) {
      return newItem[0]?.lastUpdateDate ? newItem[0]?.lastUpdateDate : "";
    } else {
      return "N/A";
    }
  };

  const getDevActivitys = (item) => {
    const newItem = item.filter((val) => val.deploymentType === "DEV");
    if (newItem.length > 0) {
      return newItem[0]?.lastUpdateDate ? newItem[0]?.lastUpdateDate : "";
    } else {
      return "N/A";
    }
  };
  const getDevActivity = (item) => {
    const newItem = item.filter((val) => val.deploymentType === "DEV");
    if (newItem.length > 0) {
      return (
        <>
          {item.map((val, i) => (
            <span key={i}>
              {val?.deploymentType === "DEV"
                ? val?.lastUpdateDate
                  ? moment(val?.lastUpdateDate).format("DD MMM YYYY, hh:mm")
                  : "N/A"
                : ""}
            </span>
          ))}
        </>
      );
    } else {
      return "N/A";
    }
  };
  const getColumnVisibility = (tab) => {
    const visibility = {
      name: true,
      type: true,
      status: !(tab === 0 || tab === 1 || tab === 2),
      Id: !(tab === 2 || tab === 3),
      email: !(tab === 1 || tab === 0 || tab === 3),
      owner: true,
      verified: !(tab === 2 || tab === 1 || tab === 0 || tab === 3),
      lastActivity: tab !== 3,
      prodActivity: !(tab === 0 || tab === 1 || tab === 2),
      devActivity: !(tab === 0 || tab === 1 || tab === 2),
    };
    return visibility;
  };

  const visibile = getColumnVisibility(tab);

  const tableHeadings = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: tab !== 3 && tab !== 2 ? 270 : largeDev ? 210 : 200,
      headerAlign: "left",
      align: "left",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: switchDashs(
                params.row.company ? params.row.company : params.row
              )
                ? "pointer"
                : params.row.applicationId
                ? "pointer"
                : "default",
              color: "#0094CD",
              display: "flex",
            }}
            onClick={() =>
              params.row.userId
                ? switchUser(params.row)
                : params.row.applicationId
                ? switchApplication(params.row)
                : switchDash(params.row)
            }
          >
            <img
              src={imageUrl(params.row)}
              alt="icon"
              width={31}
              heigth={31}
            ></img>
            <span style={colStyleName}>{params.row.name}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "type",
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.type
          ? row.type === "CUSTOMER" || row.type === "RESELLER"
            ? "Customer"
            : row.type
          : row.role
          ? getType(row)
          : row.productId.productId === 1
          ? "Wrap App"
          : row.productId.productId === 2
          ? "Max App"
          : row.productId.productId === 3
          ? "Switch App"
          : row.productId.productId === 4
          ? "Reach App"
          : row.productId.productId === 5
          ? "S-Direct App"
          : row.productId.productId === 8
          ? "Connect App"
          : row.productId.productId === 7
          ? "D-Direct App"
          : "",
      }),
      headerName: tab === 2 ? "User Type" : "Type",
      width: tab !== 3 && tab !== 2 ? 250 : largeDev ? 180 : 120,
      renderCell: (params) => {
        if (params.row.type) {
          return (
            <div style={{ textTransform: "capitalize" }}>
              {params.row.type === "CUSTOMER" || params.row.type === "RESELLER"
                ? "Customer"
                : params.row.type.toLowerCase()}
            </div>
          );
        } else if (params.row.role) {
          return (
            <div style={{ textTransform: "capitalize" }}>
              {getType(params.row)}
            </div>
          );
        } else {
          return (
            <div style={{ textTransform: "capitalize" }}>
              {params.row.productId.productId === 1
                ? "Wrap App"
                : params.row.productId.productId === 2
                ? "Max App"
                : params.row.productId.productId === 3
                ? "Switch App"
                : params.row.productId.productId === 4
                ? "Reach App"
                : params.row.productId.productId === 5
                ? "S-Direct App"
                : params.row.productId.productId === 8
                ? "Connect App"
                : params.row.productId.productId === 7
                ? "D-Direct App"
                : ""}
            </div>
          );
        }
      },
      headerAlign: "left",
      align: "left",
    },
    {
      flex: 1,
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      type: "text",
      width: largeDev ? 130 : 110,
      hide: tab === 1 || tab === 2 || tab === 0 ? true : "",
      headerAlign: "left",
      align: "left",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      flex: 1,
      field: "Id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      width: tab !== 0 ? (largeDev ? 160 : 150) : 230,
      hide: tab === 2 || tab === 3 ? true : "",
      identity: true,
      headerAlign: "left",
      align: "left",
      type: "number",
      valueGetter: (value, row) => ({
        Id: row.companyId
          ? row.companyId
          : row.applicationId
          ? row.applicationId
          : row.userId,
      }),
      sortComparator: idComparator,
      renderCell: (params) => {
        if (params.row.companyId) {
          return <div>{params.row.companyId}</div>;
        } else if (params.row.userId) {
          return <div>{params.row.userId}</div>;
        } else if (
          params.row.deploymentDetails &&
          params.row.deploymentDetails.length > 0
        ) {
          return <div>{params.row.deploymentDetails[0].appId}</div>;
        } else {
          return <div>{""}</div>;
        }
      },
    },
    {
      flex: 1,
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      hide: tab === 1 || tab === 0 || tab === 3 ? true : "",
      width: 250,
      headerAlign: "left",
      align: "left",
      type: "email",
      valueGetter: (value, row) => ({
        vals: row?.email ? row?.email : "",
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        if (params?.row?.email) {
          return <div style={colStyle}>{params?.row?.email}</div>;
        } else {
          return <div style={colStyle}>{"N/A"}</div>;
        }
      },
    },

    {
      flex: 1,
      field: "owner",
      headerClassName: "super-app-theme--header",
      headerName: "Owner",
      width: tab !== 3 ? 200 : largeDev ? 180 : 200,
      headerAlign: "left",
      align: "left",
      type: "text",
      valueGetter: (value, row) => ({
        vals: row?.parentCompanies ? row?.parentCompanies?.name : "",
      }),

      sortComparator: nameComparator,
      renderCell: (params) => {
        if (params?.row?.userId) {
          return (
            <div
              style={{
                cursor: switchDashs(params.row?.company)
                  ? "pointer"
                  : "default",
                color: "#0094CD",
              }}
              onClick={() => switchDash(params.row?.company)}
            >
              {params.row?.owner ? (
                <span style={colStyle}>{params.row?.owner}</span>
              ) : (
                "N/A"
              )}
            </div>
          );
        } else if (params?.row?.applicationId) {
          return (
            <div
              style={{
                cursor: switchDashs(params.row?.parentCompanies?.[2])
                  ? "pointer"
                  : "default",
                color: "#0094CD",
              }}
              onClick={() => switchDash(params.row?.parentCompanies?.[2])}
            >
              {params.row?.publisherName ? (
                <span style={colStyle}>{params.row?.publisherName}</span>
              ) : (
                "N/A"
              )}
            </div>
          );
        } else {
          if (
            params.row.parentCompanies &&
            params.row.parentCompanies.length >= 2
          ) {
            const secondToLastElement =
              params.row.parentCompanies[params.row.parentCompanies.length - 2];
            return (
              <div
                style={{
                  cursor: switchDashs(secondToLastElement)
                    ? "pointer"
                    : "default",
                  color: "#0094CD",
                }}
                onClick={() => switchDash(secondToLastElement)}
              >
                {secondToLastElement?.name ? (
                  <span style={colStyle}>{secondToLastElement?.name}</span>
                ) : (
                  "N/A"
                )}
              </div>
            );
          }
        }
      },
    },

    {
      flex: 1,
      field: "verified",
      headerClassName: "super-app-theme--header",
      headerName: "Verified?",
      hide: tab === 2 || tab === 1 || tab === 0 || tab === 3 ? true : "",
      width: 140,
      headerAlign: "left",
      align: "left",
      type: "email",

      valueGetter: (value, row) => ({
        vals: row?.email
          ? row?.userVerified === "Verified"
            ? "Yes"
            : "No"
          : "",
      }),

      sortComparator: nameComparator,
      renderCell: (params) => {
        if (params?.row?.userVerified) {
          return (
            <div style={colStyle}>
              {params.row?.userVerified === "Verified" ? "Yes" : "No"}
            </div>
          );
        } else {
          return <div style={colStyle}>{"N/A"}</div>;
        }
      },
    },

    {
      flex: 1,
      field: "lastActivity",
      headerClassName: "super-app-theme--header",
      headerName: "Last Activity",
      minWidth: 180,
      hide: tab === 3 ? true : "",
      headerAlign: "center",
      align: "center",

      valueGetter: (value, row) => ({
        vals: row?.companyId
          ? row?.lastActivity
          : row?.userId
          ? row?.lastActivity
          : row.deploymentDetails && row.deploymentDetails.length > 0
          ? row.deploymentDetails[0].lastUpdateDate
          : "",
      }),

      sortComparator: nameComparator,
      renderCell: (params) => {
        if (params.row.companyId) {
          return (
            <div>
              {params.row.lastActivity
                ? moment(params.row.lastActivity).format("DD MMM YYYY, hh:mm")
                : "N/A"}
            </div>
          );
        } else if (params.row.userId) {
          return (
            <div>
              {params.row.lastActivity
                ? moment(params.row.lastActivity).format("DD MMM YYYY, hh:mm")
                : "N/A"}
            </div>
          );
        } else if (
          params.row.deploymentDetails &&
          params.row.deploymentDetails.length > 0
        ) {
          return (
            <div>
              {params.row.deploymentDetails[0].lastUpdateDate
                ? moment(params.row.deploymentDetails[0].lastUpdateDate).format(
                    "DD MMM YYYY, hh:mm"
                  )
                : "N/A"}
            </div>
          );
        } else {
          return <div>{""}</div>;
        }
      },
    },

    {
      flex: 1,
      field: "prodActivity",
      headerClassName: "super-app-theme--header",
      headerName: "Prod Last Activity",
      width: largeDev ? 200 : 165,
      hide: tab === 0 || tab === 1 || tab === 2 ? true : "",
      identity: true,
      headerAlign: "left",
      align: "left",

      valueGetter: (value, row) => ({
        vals:
          row.deploymentDetails && row.deploymentDetails.length > 0
            ? getProdActivitys(row.deploymentDetails)
            : "",
      }),

      sortComparator: nameComparator,
      renderCell: (params) => {
        if (
          params.row.deploymentDetails &&
          params.row.deploymentDetails.length > 0
        ) {
          return <div>{getProdActivity(params.row.deploymentDetails)}</div>;
        } else {
          return <div>{""}</div>;
        }
      },
    },

    {
      flex: 1,
      field: "devActivity",
      headerClassName: "super-app-theme--header",
      headerName: "Dev Last Activity",
      width: largeDev ? 200 : 170,
      hide: tab === 0 || tab === 1 || tab === 2 ? true : "",
      identity: true,
      headerAlign: "left",
      align: "left",
      type: "number",

      valueGetter: (value, row) => ({
        vals:
          row.deploymentDetails && row.deploymentDetails.length > 0
            ? getDevActivitys(row.deploymentDetails)
            : "N/A",
      }),

      sortComparator: nameComparator,
      renderCell: (params) => {
        if (
          params.row.deploymentDetails &&
          params.row.deploymentDetails.length > 0
        ) {
          return <div>{getDevActivity(params.row.deploymentDetails)}</div>;
        } else {
          return <div>{""}</div>;
        }
      },
    },
  ];
  const imageUrl = (item) => {
    if (item?.type === "CHANNEL") {
      return channelLogo;
    } else if (item?.type === "LICENSEE") {
      return licenseeLogo;
    } else if (item?.type === "CUSTOMER" || item?.type === "RESELLER") {
      return customerLogo;
    } else if (props.currentTab === 2) {
      return userLogo;
    } else if (item?.productId?.productType === "#datafree REACH") {
      return userLogo;
    } else return appLogo;
  };

  const getType = (item) => {
    if (item?.role === "user" || item?.role === "reseller") {
      return "Customer User";
    } else if (item?.role === "admin") {
      return "Admin User";
    } else if (item?.role === "licensee") {
      return "Licensee User ";
    } else if (item?.role === "channel" || item?.role === "accountmanager") {
      return "Channel User ";
    } else return item?.role && item?.role.toLowerCase() + " User";
  };

  const userData = (item) => {
    if (item?.type === "CUSTOMER" || item?.type === "RESELLER") {
      return (
        <div>
          <span
            className={
              switchDashs(item?.parentCompanies?.[0])
                ? "pointer-cursor"
                : "default-cursor"
            }
            style={{
              cursor: switchDashs(item?.parentCompanies?.[0])
                ? "pointer"
                : "default",
            }}
            onClick={() => switchDash(item?.parentCompanies?.[0])}
          >{`${item?.parentCompanies?.[0]?.name}`}</span>
          <span
            className={
              switchDashs(item?.parentCompanies?.[1])
                ? "pointer-cursor"
                : "default-cursor"
            }
            style={{
              cursor: switchDashs(item?.parentCompanies?.[1])
                ? "pointer"
                : "default",
            }}
            onClick={() => switchDash(item?.parentCompanies?.[1])}
          >{` > ${item?.parentCompanies?.[1]?.name}`}</span>
        </div>
      );
    } else if (item?.type === "CHANNEL") {
      return (
        <div
          className={
            switchDashs(item?.parentCompanies?.[1])
              ? "pointer-cursor"
              : "default-cursor"
          }
          style={{
            cursor: switchDashs(item?.parentCompanies?.[1])
              ? "pointer"
              : "default",
          }}
          onClick={() => switchDash(item?.parentCompanies?.[1])}
        >
          {`${item?.parentCompanies?.[1]?.name}`}
        </div>
      );
    } else if (item?.type === "LICENSEE") {
      return (
        <div
          className={
            switchDashs(item?.parentCompanies?.[0])
              ? "pointer-cursor"
              : "default-cursor"
          }
          style={{
            cursor: switchDashs(item?.parentCompanies?.[0])
              ? "pointer"
              : "default",
          }}
          onClick={() => switchDash(item?.parentCompanies?.[0])}
        >{`${item?.parentCompanies?.[0]?.name}`}</div>
      );
    } else if (item?.publisherName) {
      return (
        <div>
          <span
            className={
              switchDashs(item?.parentCompanies?.[0])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[0])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[0])
                ? "pointer"
                : "default",
            }}
          >
            {item?.parentCompanies?.[0]?.name}
          </span>
          <span
            className={
              switchDashs(item?.parentCompanies?.[1])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[1])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[1])
                ? "pointer"
                : "default",
            }}
          >{` > ${item?.parentCompanies?.[1]?.name}`}</span>
          <span
            className={
              switchDashs(item?.parentCompanies?.[2])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[2])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[2])
                ? "pointer"
                : "default",
            }}
          >{` > ${item?.publisherName}`}</span>
        </div>
      );
    } else if (item?.role === "user") {
      return (
        <div>
          <span
            className={
              switchDashs(item?.parentCompanies?.[0])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[0])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[0])
                ? "pointer"
                : "default",
            }}
          >
            {item?.parentCompanies?.[0]?.name}
          </span>
          <span
            className={
              switchDashs(item?.parentCompanies?.[1])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[1])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[1])
                ? "pointer"
                : "default",
            }}
          >{` > ${item?.parentCompanies?.[1]?.name}`}</span>
          <span
            className={
              switchDashs(item?.company) ? "pointer-cursor" : "default-cursor"
            }
            onClick={() => switchDash(item?.company)}
            style={{
              cursor: switchDashs(item?.company) ? "pointer" : "default",
            }}
          >{` > ${item?.owner}`}</span>
        </div>
      );
    } else if (item?.role === "channel") {
      return (
        <div>
          <span
            className={
              switchDashs(item?.parentCompanies?.[1])
                ? "pointer-cursor"
                : "default-cursor"
            }
            onClick={() => switchDash(item?.parentCompanies?.[1])}
            style={{
              cursor: switchDashs(item?.parentCompanies?.[1])
                ? "pointer"
                : "default",
            }}
          >{`${item?.parentCompanies?.[1]?.name}`}</span>
          <span
            className={
              switchDashs(item?.company) ? "pointer-cursor" : "default-cursor"
            }
            onClick={() => switchDash(item?.company)}
            style={{
              cursor: switchDashs(item?.company) ? "pointer" : "default",
            }}
          >{` > ${item?.owner}`}</span>
        </div>
      );
    } else if (item?.role === "licensee") {
      return (
        <div
          style={{ cursor: switchDashs(item?.company) ? "pointer" : "default" }}
        >
          <span
            className={
              switchDashs(item?.company) ? "pointer-cursor" : "default-cursor"
            }
            onClick={() => switchDash(item?.company)}
          >{`${item?.owner}`}</span>
        </div>
      );
    } else if (item?.role === "admin") {
      return (
        <div className="pointer-cursor" style={{ cursor: "pointer" }}>
          {item?.owner}
        </div>
      );
    } else {
      return (
        <div className="pointer-cursor" style={{ cursor: "pointer" }}>
          {item?.owner}
        </div>
      );
    }
  };
  const userDetails = (item) => {
    if (item?.companyId) {
      return (
        <span>
          <span
            style={{
              color: "#01537A",
              textTransform: "capitalize",
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            ID
            <span
              style={{
                color: "#01537A",
                fontWeight: 400,
                fontSize: "16px",
                marginLeft: "10px",
              }}
            >
              {item?.companyId && item?.companyId}
            </span>
          </span>
          <br />
          {item?.type === "CUSTOMER" || item?.type === "RESELLER"
            ? "Customer Company"
            : item?.type === "CHANNEL"
            ? "Channel Company"
            : item?.type === "LICENSEE"
            ? "Licensee Company"
            : ""}
          <br />
          {` ${item?.status && item?.status}`}
        </span>
      );
    } else if (item?.applicationId) {
      return (
        <span style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span style={{ display: "flex" }}>
            {item.deploymentDetails && item.deploymentDetails.length > 0
              ? item?.deploymentDetails.map((apps, index) =>
                  apps?.deploymentType === "DEV" ? (
                    <span key={index}>
                      <span
                        style={{
                          fontWeight: 700,
                          color: "#01537A",
                          letterSpacing: "1.32px",
                        }}
                      >
                        Dev
                      </span>
                      <span
                        style={{
                          fontWeight: 400,
                          color: "#01537A",
                          letterSpacing: "1.32px",
                          marginRight: "10px",
                        }}
                      >
                        {" "}
                        {apps?.appId && apps?.appId}
                      </span>
                    </span>
                  ) : apps?.deploymentType === "PROD" ? (
                    <span key={index}>
                      <span
                        style={{
                          fontWeight: 700,
                          color: "#01537A",
                          letterSpacing: "1.32px",
                        }}
                      >
                        Prod{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: 400,
                          color: "#01537A",
                          letterSpacing: "1.32px",
                          marginRight: "10px",
                        }}
                      >
                        {" "}
                        {apps?.appId && apps?.appId}
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                )
              : ""}
          </span>
          <span
            style={{ display: "flex", flexDirection: "column", gap: "5px" }}
          >
            <span>
              {item?.productId.productId === 1
                ? "Wrap"
                : item?.productId.productId === 2
                ? "Max"
                : item?.productId.productId === 3
                ? "Switch"
                : item?.productId.productId === 4
                ? "Reach"
                : item?.productId.productId === 5
                ? "S-Direct"
                : item?.productId.productId === 8
                ? "Connect"
                : item?.productId.productId === 7
                ? "D-Direct"
                : ""}
            </span>
            <span>{item?.status ? item?.status : "N/A"}</span>
            <span>{dataUsage(item)}</span>
          </span>
        </span>
      );
    } else if (item?.userId) {
      return (
        <>
          <span
            style={{
              letterSpacing: "1.32px",
            }}
          >
            <span
              style={{
                color: "rgba(1, 83, 122, 1)",
                textTransform: "none",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              {item?.email ? <span>{item?.email}</span> : ""}
              <br />
              <span
                style={{
                  color: "#5A5656",
                  fontWeight: 400,
                  fontSize: "16px",
                  letterSpacing: "1.32px",
                }}
              >
                {getType(item)}
              </span>

              <br />
            </span>

            {`${item?.status}`}
          </span>
        </>
      );
    }
  };
  const dataUsage = (item) => {
    if (item?.applicationId) {
      return (
        <Box
          component="span"
          sx={{
            color: "#5A5656",
            fontWeight: "300",
            fontSize: "13px",
            display: "flex",
          }}
        >
          <LightTooltip
            title="Last 28 day Datafree data usage"
            placement="right-start"
          >
            <img src={dataIcon} alt=""></img>
          </LightTooltip>
          <span style={{ marginTop: "1px", marginLeft: "5px" }}>
            {item.prodDataUsage28Days
              ? item.prodDataUsage28Days !== null
                ? item?.prodDataUsage28Days + " MB"
                : " N/A"
              : "N/A"}
          </span>
        </Box>
      );
    }
  };

  const statusUpdate = (status) => {
    if (status === "Suspended") {
      localStorage.setItem("switchStatus", true);
      localStorage.setItem("archivedStatus", false);
      archivedStatusContextVal(false);
      switchStatusContextVal(true);
    } else if (status === "Archived") {
      localStorage.setItem("switchStatus", false);
      switchStatusContextVal(false);
      localStorage.setItem("archivedStatus", true);
      archivedStatusContextVal(true);
    } else {
      localStorage.setItem("switchStatus", false);
      localStorage.setItem("archivedStatus", false);
      archivedStatusContextVal(false);
      switchStatusContextVal(false);
    }
  };
  const switchUser = (item) => {
    if (item?.companyType === "LICENSEE") {
      localStorage.setItem(
        "parentCompId",
        item?.parentCompanies?.[1]?.companyId
      );
      updateBreadCrumbData(item?.parentCompanies?.[1]?.companyId);
    } else if (
      item?.companyType === "CHANNEL" ||
      item?.companyType === "CUSTOMER"
    ) {
      updateBreadCrumbData(item?.parentCompanies?.[2]?.companyId);
      localStorage.setItem(
        "parentCompId",
        item?.parentCompanies?.[2]?.companyId
      );
    }
    localStorage.setItem("userRole", item?.role);
    localStorage.setItem("selectType", item?.role);
    userRoleDispatchContextVal(item?.role);
    navigate("/user-details", { state: { clickedUserId: item?.userId } });
  };

  const switchApplication = (item) => {
    if (
      item?.productId?.ProductType === "#datafree REACH" ||
      item?.productId?.ProductType === "#datafree WRAP" ||
      item?.productId?.ProductType === "#datafree SWITCH"
    ) {
      navigate("/application-details", {
        state: { clickedAppId: item?.applicationId },
      });
    }
  };
  const switchApp = (value) => {
    if (value === "#datafree REACH") {
      return true;
    } else if (value === "#datafree WRAP") {
      return true;
    } else if (value === "#datafree SWITCH") {
      return true;
    } else {
      return false;
    }
  };
  const switchDash = (item) => {
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";
    const userRole = localStorage.getItem("role");

    if (type === "BINU" && userRole === "ROLE_ADMIN") {
      statusUpdate(item?.status);
      navigate("/");
    } else if (
      type === "LICENSEE" &&
      (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
    ) {
      if (userRole === "ROLE_LICENSEE") {
        navigate("/");
      } else if (userRole === "ROLE_ADMIN") {
        localStorage.setItem("licenseeId", item?.companyId);
        navigate("/licensee", { state: { clickedCompId: item?.companyId } });
      }
    } else if (
      type === "CHANNEL" &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL")
    ) {
      statusUpdate(item?.status);
      if (userRole === "ROLE_CHANNEL") {
        navigate("/");
      } else {
        localStorage.setItem("channelId", item?.companyId);
        navigate("/channel", { state: { clickedCompId: item?.companyId } });
      }
    } else if (
      (type === "CUSTOMER" || type === "RESELLER") &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL" ||
        userRole === "ROLE_USER")
    ) {
      statusUpdate(item?.status);
      if (userRole === "ROLE_USER") {
        navigate("/");
      } else {
        localStorage.setItem("customerId", item?.companyId);
        navigate("/customer", { state: { clickedCompId: item?.companyId } });
      }
    }
  };

  const switchDashs = (item) => {
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";
    const userRole = localStorage.getItem("role");
    if (type === "BINU" && userRole === "ROLE_ADMIN") {
      return true;
    } else if (
      type === "LICENSEE" &&
      (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
    ) {
      return true;
    } else if (
      type === "CHANNEL" &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL")
    ) {
      return true;
    } else if (
      (type === "CUSTOMER" || type === "RESELLER") &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL" ||
        userRole === "ROLE_USER")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        width: props.filterClose ? "100%" : { xs: "77%", xl: "88%" },
      }}
    >
      {gridView ? (
        <List
          sx={{
            display: "grid",
            columnGap: {
              xs: "2rem",
              md: "1rem",
              lg: "1rem",
              xl: "4rem",
            },
            rowGap: { xs: "2rem", lg: "1rem" },
            gridTemplateColumns: {
              xs: "auto",
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
            },
            gridTemplateRows: "repeat(1, 1fr)",
          }}
        >
          {props.data?.map((item, index) => (
            <Paper
              key={index}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "7px",
                background: "var(--White, #FFF)",
                boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <ListItem
                alignItems="flex-start"
                style={{
                  paddingRight: "10px",
                  margin: "8px",
                  cursor: "pointer",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      height: 63,
                      width: 67,
                      mr: 2,
                      cursor:
                        switchDashs(item?.userId ? item?.company : item) ||
                        switchApp(item?.productId?.ProductType)
                          ? "pointer"
                          : "default",
                    }}
                    alt="Remy Sharp"
                    src={imageUrl(item)}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() =>
                      item?.userId
                        ? switchUser(item)
                        : item?.applicationId
                        ? switchApplication(item)
                        : switchDash(item)
                    }
                  />
                </ListItemAvatar>

                <ListItemText
                  style={{
                    color: "#0000008a",
                    fontSize: "14px",
                    textTransform: "uppercase",
                  }}
                  fontWeight="500"
                  primary={
                    <Typography
                      sx={{ display: "flex" }}
                      variant="body2"
                      component="span"
                      style={{
                        color: "rgba(90, 86, 86, 1)",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "300",
                        lineHeight: "19.373px",
                        letterSpacing: "1.318px",
                        textTransform: "capitalize",
                      }}
                      textTransform="capitalize"
                    >
                      {userData(item)}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Paper
                        sx={{
                          boxShadow: "unset !important",
                          cursor:
                            switchDashs(item?.userId ? item?.company : item) ||
                            switchApp(item?.productId?.ProductType)
                              ? "pointer"
                              : "default",
                        }}
                        onMouseEnter={() => setIsHovered(index)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() =>
                          item?.userId
                            ? switchUser(item)
                            : item?.applicationId
                            ? switchApplication(item)
                            : switchDash(item)
                        }
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            ...(switchDashs(
                              item?.userId ? item?.company : item
                            ) || switchApp(item?.productId?.ProductType)
                              ? {
                                  color:
                                    isHovered === index
                                      ? "#0094CD !important"
                                      : "#01537A",
                                  textDecoration:
                                    isHovered === index ? "underline" : "",
                                  fontWeight: isHovered === index ? "500" : "",
                                }
                              : ""),
                          }}
                          component="label"
                          variant="body2"
                          style={{
                            color: "#01537A",
                            fontWeight: "500",
                            fontSize: "22px",
                            lineHeight: "25.87px",
                            letterSpacing: "1.32px",
                          }}
                          textTransform="uppercase"
                        >
                          <span
                            style={{
                              cursor: switchDashs(
                                item?.userId ? item?.company : item
                              )
                                ? "pointer"
                                : switchApp(item?.productId?.ProductType)
                                ? "pointer"
                                : "default",
                            }}
                          >
                            {item?.name}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ display: "flex", flexDirection: "column" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          textTransform="none"
                          style={{
                            color: "#5A5656",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          {userDetails(item)}
                        </Typography>
                      </Paper>
                    </>
                  }
                />
              </ListItem>
            </Paper>
          ))}
        </List>
      ) : props?.data &&
        props?.data.length > 0 &&
        !props?.loading &&
        !gridView ? (
        <Box
          sx={{
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor:
                switchStatusContextVal && statusContextVal
                  ? "#373737"
                  : "#01537A !important",
              color: "white",
            },
          }}
        >
          <DataTable
            type="search"
            rows={props?.data}
            columns={tableHeadings}
            size={props.size}
            tableName="AllSearchList"
            loading={props?.loading}
            tab={tab}
            columnVisibilityModel={visibile}
          />
        </Box>
      ) : null}
    </Box>
  );
}
