import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PrivateRoutes from "./utils/privateRoute";
import Admin from "./pages/admin";
import Login from "./pages/login";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./theme.js";
import Licensee from "./pages/licensee";
import Channel from "./pages/channel";
import User from "./pages/user";
import Search from "../src/components/New Search/searchAdmin.js";
import LicenseeSearch from "../src/components/New Search/searchLicensee.js";
import ChannelSearch from "../src/components/New Search/searchChannel.js";
import UserSearch from "../src/components/New Search/searchPublisher.js";

import { LicenseInfo } from "@mui/x-license-pro";
import AccountSettings from "./pages/licensee/accountSettings";
import ChannelAccountSettings from "./pages/channel/channelAccountSettings";
import UserAccountSettings from "./pages/user/userAccountSettings";
import ServiceDetails from "./pages/licensee/servicedetails";
import { ContextProvider } from "./context/serviceContext";
import AddUser from "./pages/admin/addUser";
import PublisherAddUser from "./pages/user/addUser";
import ChannelAddUser from "./pages/channel/addUser";
import LicenseeAddUser from "./pages/licensee/addUser.js";
import UserDetail from "./pages/admin/userDetail";
import LicenseeUserDetail from "./pages/licensee/userDetail";
import ChannelUserDetail from "./pages/channel/userDetail";
import UserUserDetail from "./pages/user/userDetail";
import AddCompany from "./pages/admin/addCompany";
import LicenseeAddCompany from "./pages/licensee/addCompany";
import ChannelAddCompany from "./pages/channel/addCompany";
import ListRoles from "./pages/listRoles";
import AddApplication from "./pages/applications/addApplication";
import ApplicationDetails from "./pages/applications/applicationDetails";
import ReportingDetails from "./pages/report/reportingDetails";
import PublicRoutes from "./utils/publicRoute.js";
import { UserProvider } from "./context/userContext";
import { BreadCrumbProvider } from "./context/breadCrumbContext.js";
import ErrorPage from "./pages/workbench/errorPage.js";
import WorkBenchPage from "./pages/workbench/workBenchPage.js";
import { AppVersionProvider } from "./context/appVersionContext.js";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
let userRole = localStorage.getItem("role");

function App() {
  const [baseUrlPage, setBaseUrlPage] = useState();

  const getComponentByRole = (role, componentMap) => {
    console.log("role", role);
    return componentMap[role] || <Login />;
  };

  const determineBaseUrlPage = (tld) => {
    return tld === "testct.datafr.ee";
  };
  useEffect(() => {
    const tld = process.env.REACT_APP_TLD;
    setBaseUrlPage(determineBaseUrlPage(tld));
  }, []);

  return (
    <ContextProvider>
      <BreadCrumbProvider>
        <UserProvider>
          <AppVersionProvider>
            <ThemeProvider theme={theme}>
              <Router>
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path="/"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <Admin />,
                        ROLE_LICENSEE: <Licensee />,
                        ROLE_CHANNEL: <Channel />,
                        ROLE_USER: <User />,
                      })}
                      exact
                    />

                    <Route
                      path="search"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <Search />,
                        ROLE_LICENSEE: <LicenseeSearch />,
                        ROLE_CHANNEL: <ChannelSearch />,
                        ROLE_USER: <UserSearch />,
                      })}
                      exact
                    />

                    <Route
                      path="channel"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <Admin />,
                        ROLE_LICENSEE: <Licensee />,
                      })}
                      exact
                    />
                    <Route
                      path="customer"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <Admin />,
                        ROLE_LICENSEE: <Licensee />,
                        ROLE_CHANNEL: <Channel />,
                      })}
                      exact
                    />
                    <Route
                      path="accountsettings"
                      element={getComponentByRole(userRole, {
                        ROLE_LICENSEE: <AccountSettings />,
                        ROLE_CHANNEL: <ChannelAccountSettings />,
                        ROLE_USER: <UserAccountSettings />,
                      })}
                      exact
                    />

                    <Route
                      path="licensee/accountsettings"
                      element={<AccountSettings />}
                    ></Route>

                    <Route
                      path="/create-application"
                      element={<AddApplication />}
                    ></Route>
                    <Route
                      path="/reporting"
                      element={<ReportingDetails />}
                    ></Route>
                    <Route
                      path="/application-details"
                      element={<ApplicationDetails />}
                    ></Route>

                    <Route
                      path="channel/accountsettings/"
                      element={<ChannelAccountSettings />}
                    ></Route>
                    <Route
                      path="customer/accountsettings/"
                      element={<UserAccountSettings />}
                    ></Route>

                    <Route
                      path="servicedetails/:compId"
                      element={<ServiceDetails />}
                    ></Route>

                    <Route
                      path="licensee/search"
                      element={<LicenseeSearch />}
                    ></Route>
                    <Route
                      path="channel/search"
                      element={<ChannelSearch />}
                    ></Route>
                    <Route
                      path="customer/search"
                      element={<UserSearch />}
                    ></Route>
                    <Route path="licensee" element={<Admin />}></Route>

                    <Route
                      path="/create-user"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <AddUser />,
                        ROLE_LICENSEE: <LicenseeAddUser />,
                        ROLE_CHANNEL: <ChannelAddUser />,
                        ROLE_USER: <PublisherAddUser />,
                      })}
                      exact
                    />
                    <Route
                      path="/user-details"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <UserDetail />,
                        ROLE_LICENSEE: <LicenseeUserDetail />,
                        ROLE_CHANNEL: <ChannelUserDetail />,
                        ROLE_USER: <UserUserDetail />,
                      })}
                      exact
                    />
                    <Route
                      path="/create-company"
                      element={getComponentByRole(userRole, {
                        ROLE_ADMIN: <AddCompany />,
                        ROLE_LICENSEE: <LicenseeAddCompany />,
                        ROLE_CHANNEL: <ChannelAddCompany />,
                        ROLE_USER: <PublisherAddUser />,
                      })}
                      exact
                    />

                    <Route path="/listRoles" element={<ListRoles />} exact />
                    <Route
                      path="/new-workbench"
                      element={getComponentByRole(baseUrlPage, {
                        true: <WorkBenchPage />,
                        false: <ErrorPage />,
                      })}
                    />
                  </Route>

                  <Route path="/login" element={<PublicRoutes />}>
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </AppVersionProvider>
        </UserProvider>
      </BreadCrumbProvider>
    </ContextProvider>
  );
}

export default App;
